import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import config from '../config/config';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import StateHelper from '../services/stateHelper';
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import InviteEmail from '../components/InviteEmail';

const fs = new StateHelper();

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    img: {
        // height: "calc(100vh - 102px)",
        display: 'block',
        overflow: 'hidden',
        // width: '100%',
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        paddingBottom: "3em"
    },
    slideItem: {
    },
    text: {
        textAlign: "center",
        fontWeight: "900",
        color: "#ffffff",
        maxWidth: "80%",
        textShadow: "2px 2px 10px rgba(0,0,0,0.65)"
    },
    join: {
        background: "#0044aa",
        color: "#ffffff",
        width: "100%",
        padding: '6em 8em',
        textAlign: "center"
    },
    joinTransparent: {
        background: 'transparent',
        color: "#ffffff",
        width: "100%",
        textAlign: "center"
    },
    joinText: {
        padding: "0.1em 0"
    },
    testimonialsContent : {
        display: "-webkit-box",
        "-webkit-line-clamp": "5",
        "-webkit-box-orient": "vertical",  
        overflow: "hidden"
    },
    joinInput: {
        background: "#fff",
        margin: "0 0.25em"
    },
    joinButton: {
        margin: "0 0.25em",
        marginLeft: '1em',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#00bbff',
        color: '#fff',
        fontSize: '1em',
        borderRadius: '5px',
        backgroundColor: 'transparent'
    },
    joinInputs: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2em"
    },
    learn: {
        textAlign: "right",
        padding: '2em 8em',
    },
    learnItem: {
        
    },
    aboutUs: {
        border: "1px solid #000",
    },
    contactForm: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `solid 2px #000`,
        flexDirection: 'column',
        padding: "1em 2em",
        width: "100%",
        margin: "auto",
        backgroundColor: "#000",
    },
    contactItem: {
        display: "flex",
        padding: "0.25em 0"
    },
    learnInputs: {
        maxWidth: "450px",
        marginTop: "2em"
    },
    learnInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    carousel: {
        position: "relative"
    },
    stepperContainer: {
        position: "absolute",
        bottom: "1em",
        width: "100%",
        display: "flex",
        justifyContent: 'center'
    },
    team: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0em 0em"
    },
    teamImg: {
        width: "100%"
    },
    details: {
        padding: "10em 2em",
        width: "100%"
    },
    detailItem: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "1em 0"
    },
    detailItemInner: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.25em 0"
    },
    endImg: {
        width: "100%",
    },
    loading: {
        color: "#fff"
    },
    testimonialsPhoto: {
        height: "80px",
        width: "80px",
        marginBottom: "1.5em"
    },
    testimonialsItemInner: {
        width: "100%",
        padding: "1em 1.3em",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "#000",
        color: "#fff",
        height: "100%"
    },
    testimonialsItem: {
        padding: "0.5em"
    },
    testimonialsContainer: {
        padding: "3em 0",
        height: "100%"
    },
    tags:{
        display: 'flex',
        padding: '0.5em 0'
    },
    chip:{
        margin: '0 3px'
    }
}));

const Home = (props) => {
    const { history } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [slides, setSlides] = React.useState([]);
    const [testimonials, setTestimonials] = React.useState([]);
    const [articles, setArticles] = React.useState([]);
    const [videos, setVideos] = React.useState([]);

    const getVideos = async () => {
        const art = crud('videos');
        let res = await art.find({_sort: "createdAt:DESC", _limit: 1});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    const vids = res.data.map(v=>{
                        const matches =  v.hyperlink.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
                        if(matches && matches.length > 0) {
                            return {
                                ...v,
                                vid: matches[1]
                            }
                        }
                        return v;
                    })
                    setVideos(vids);
                }
            }
        }
    }

    const getBanners = async () => {
        const art = crud('banners');
        let res = await art.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    const s = [];
                    for(const a of res.data) {
                        s.push({
                            imgPath: `${config.app.server.url}/storage/${a.photos[0]}`,
                            id: a._id
                        });
                    }
                    setSlides(s);
                }
            }
        }
    }

    const getArticles = async () => {
        const art = crud('articles');
        let res = await art.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    const s = [];
                    for(const a of res.data) {
                        s.push({
                            img: `${config.app.server.url}/storage/${a.photos[0]}`,
                            id: a._id,
                            title: a.title,
                            text: `${a.body.substring(0, 300)}...`,
                            author: a.author,
                            date: a.date,
                        });
                    }
                    setArticles(s);
                }
            }
        }
    }

    const getTestimonials = async () => {
        const prod = crud('testimonials');
        let res = await prod.find({_sort: "createdAt:DESC", _limit: 3});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    // const test = res.data.reduce((r, e, i) =>
                    //     (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r
                    // , []);

                    setTestimonials(res.data);
                }
            }
        }
    }    

    const onLinkClick = (url) => {
        history.push(url);
    }

    React.useEffect(()=>{
        getBanners();
        getTestimonials();
        getArticles();
        getVideos();
    },[]);    

    const [activeSteps, setActiveSteps] = React.useState({banner: 0, testimonials});
    const maxSteps = slides.length;

    const handleStepChange = (type, step) => {
        setActiveSteps({...activeSteps, [type]: step});
    };


    return (
        <div>
            <div className={classes.carousel}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeSteps.banner}
                    onChangeIndex={(s)=>{handleStepChange("banner",s)}}
                    interval={50000}
                    enableMouseEvents
                >
                    {slides.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeSteps.banner - index) <= 2 ? (
                        <div className={classes.slideItem}>
                            <div className={classes.img} 
                            // style={{background: `url(${step.imgPath}) no-repeat center center / cover`}}
                            >
                                <img style={{width: "100%"}} src={step.imgPath} />
                                {/**<Button className="banner-item-button" onClick={()=>{onLinkClick(`/banners/${step.id}`)}} style={{margin: "1em 0", position: "absolute", bottom: "2em"}} color="primary" variant="contained">READ ME</Button>*/}
                            </div>
                        </div>
                        
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
                <div className={classes.stepperContainer}>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeSteps.banner}
                        style={{background: "none"}}
                        className={classes.stepper}
                    />
                </div>
            </div>
           <div className="content-main-padding">
                <Grid container>
                    <Grid item sm={12} md={6} className={classes.team}>
                        <div className="overlay-aboutUS">
                        <img className={classes.aboutUs} src="./assets/images/WHOWEARE.png"/>
                        </div>
                    </Grid>
                    <Grid item sm={12} md={6} style={{overflow: "hidden"}}>
                        <div style={{display: "table-cell", verticalAlign: "middle"}}>
                            <div style={{padding: "0.5em 0 0 0"}}>
                                <Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center",textTransform: "uppercase" }}>About us</Typography>                        
                                <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center",textTransform: "uppercase"}}>LET's MEET</Typography>
        
                                <Typography style={{paddingTop: "1em"}}> 
                                1670 Mabuhay provides an experience, financial freedom, financial security and a secure future to the entrepreneurial market in a Mabuhay culture with an inspirational, motivational, and empathetic voice. Helping them feel secure, supported and proud with a dynamic, agile, ambitious, startup mindset.
                                </Typography>
                                <center>
                                    <Button href="/#/team" className="button-default" style={{marginTop: "2em", color: "#fff", border: "1px solid #ff3838" }} variant="outlined">MEET THE TEAM</Button>
                                </center>
                            </div>
                        </div>
                        {/** <Typography style={{paddingTop: "1em"}}>
                            Established in 2018, the 1670 Mabuhay Planners and Associates (1670 Mabuhay) is a trusted partner agency of FWD Insurance Philippines formed by successful and elite financial leaders in Cebu. We are a team of highly motivated and ambitious financial advisors whose primary goal is to achieve financial freedom.
                        </Typography>
                        <Typography style={{paddingTop: "1em"}}>
                            As we are in the industry of protecting the lives of every Filipino family, we strongly uphold the value of accountability, remain ethical in whatever we do, and align ourselves to our vision and mission. 
                        </Typography>
                        <center><Button href="/#/team" className="button-default" style={{marginTop: "2em", color: "#fff", border: "1px solid #1162b4" }} color="primary" variant="outlined">FIND OUT MORE ABOUT US</Button></center>
                        */}
                    </Grid>
                </Grid>
           </div>
           <div className="content-main-padding" style={{background: "#040404", padding: "0px"}}>
           {/**<Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center",textTransform: "uppercase" }}>Core Values</Typography>                        
                                <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center",textTransform: "uppercase"}}>Let's be One</Typography>**/}
        
                <Box className={classes.team}><img className={classes.teamImg} src="./assets/images/Core-Values-01.png"/></Box>
                {/**<Typography  style={{textAlign:"center", fontFamily: "Boldstrom"}} variant="h5">"WE BUILD EXCEPTIONAL TALENTS"</Typography>*/}
           </div>
           <div className="content-main-padding" style={{background: "#040404", padding: "0px", position:"relative"}}>
               <img className={classes.teamImg} src="./assets/images/WHATWEDO.png"/>
                <div className="product-button-container">
                    <Button href="/#/products" className="product-button" style={{marginTop: "2em", color: "#fff", border: "1px solid #ff3838" }} variant="outlined">Click Here</Button>
                </div>                     
           </div>
           <div className="content-main-padding reviews">
           <Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center",textTransform: "uppercase" }}>testimonials</Typography>                        
                    <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center",textTransform: "uppercase"}}>LET'S hear it</Typography>
               <div>
                    <Grid container className={classes.testimonialsContainer}>
                        {(
                            testimonials.map((t, i)=>(
                            <Grid key={i} item sm={12} md={4} lg={4} className={classes.testimonialsItem}>
                                <div className={classes.testimonialsItemInner}>
                                    <div className='speech-bubble'>
                                        <Typography className={classes.joinText +' '+classes.testimonialsContent} variant="h6" style={{marginTop: "1em", fontSize: "small"}}>"<span dangerouslySetInnerHTML={{__html: t.comments.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></span>"</Typography>
                                        <a href={'/testimonials'} onClick={(e)=>{e.preventDefault(); onLinkClick(`/testimonials`)}}><Typography  className="article-item-readmore" variant="subtitle2" style={{padding: "1em 2em", textAlign: "right"}}>Read More</Typography></a>
                                    </div>
                                    <Grid container style={{padding: "1.6em 0em"}}>
                                        <Grid item sm={4} md={4} lg={4}>
                                            <div className={classes.testimonialsPhoto+ ' testimonials-photo'} style={{background: `url(${config.app.server.url}/storage/${t.photo[0]}) no-repeat center center / cover`, position: "relative", zIndex: "1", borderRadius: "50%" }} ></div>
                                        </Grid>
                                        <Grid item sm={8} md={8} lg={8} style={{padding: "0.5em 0em"}}>
                                            <Typography className={classes.joinText} style={{paddingTop: "1em"}}>{t.name}</Typography>
                                            <Typography variant="body2" className={classes.joinText}>{t.title}</Typography>                                    
                                        </Grid>
                                    </Grid>  
                                </div>

                                        
                            </Grid>
                            ))
                        )}
                    </Grid>
               </div>
           </div>
           <hr/>
           <div className="content-main-padding blog">
                <div style={{width: "100%", padding: "0 1em 5em"}}>
                
                    <Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center",textTransform: "uppercase" }}>Latest News and Blogs</Typography>                        
                    <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center"}}>LET'S READ</Typography>
                    <Grid container className='table-blog'>
                        <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            {(articles.map((p, idx)=>(
                                <Grid container className="article-item" onClick={()=>{onLinkClick(`/blog`)}} style={{ cursor: "pointer", borderBottom: "1px solid #FFF"}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} style={{padding: "1em", borderLeft: "0px solid #000", borderRight: "0px solid #000"}}>
                                        <Typography style={{padding: "1.4em 0em 0em", color: "red"}}>
                                        {format(new Date(p.date), "MMMM dd, yyyy")}<br />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} style={{padding: "1em", borderLeft: "0px solid #000", borderRight: "0px solid #000"}} key={idx} >
                                        {/**<div style={{background: `url(${p.img}) no-repeat center center / cover`, width: "100%", height: "300px", position: "relative"}}> </div>**/}
                                        <Typography variant="h6" style={{paddingTop: "1em", textAlign: "center", color: "#FFF"}}>{p.title}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={3} style={{padding: "1em", borderLeft: "0px solid #000", }} >
                                    <Typography style={{padding: "1.4em 0em 0em", float: "right"}}>     
                                            By: {p.author}
                                        </Typography>
                                    </Grid>
                                </Grid>                     
                            ))
                            )}
                            
                            <a href={'/blog'} onClick={(e)=>{e.preventDefault(); onLinkClick(`/blog`)}} style={{float: "right", color: "red"}}>
                                <Typography className="article-item-readmore" variant="subtitle2" style={{padding: "1em 0em",  color: "red"}}>Read More </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                    </Grid>
                    
                    
                </div>
           </div>
           <InviteEmail />
           <div className="content-main-padding media">
                    <Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center", textTransform: "uppercase" }}>Media</Typography>                        
                    <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center",textTransform: "uppercase"}}>LET'S WATCH</Typography>
                <div style={{width: "100%", padding: "0 1em 2em 1em"}}>
                        {(
                    videos.map((v, idx) => (
                        <a href={'/videos'} key={idx} onClick={(e)=>{e.preventDefault(); onLinkClick(`/videos`)}} style={{color: "#fff"}} >
                            <Grid container>
                                <Grid item md={12}>
                                    <Typography variant="h4" style={{fontWeight: 700}}>{v.title}</Typography> <br/>
                                    <Box boxShadow={2}>
                                        <a href={'/videos'} onClick={(e)=>{e.preventDefault(); onLinkClick(`/videos`)}}>
                                            <div className="video-container">
                                                {v.vid && <iframe
                                                    className="responsive-iframe"
                                                    src={`https://www.youtube.com/embed/${v.vid}`}
                                                    frameBorder="0"
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="Embedded youtube"
                                                />}
                                            </div>
                                        </a>
                                    </Box>
                                    <br/>
                                    {v.description}
                                    <br/>
                                    {v.tags &&
                                        <div className={classes.tags}>
                                            <Typography style={{marginRight: "0.5em"}} variant="subtitle2">Tags: </Typography>
                                                {(v.tags.split(",").map((t)=>(
                                                    <div>
                                                        { (t.trim().length !== 0 && t.trim() !== "") &&
                                                            <Chip
                                                                clickable
                                                                label={t.trim()}
                                                                className={classes.chip}
                                                                size="small"
                                                            />
                                                        }
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </a>
                    ))
                )}
                <center><Button href="/#/videos" className="button-default" style={{marginTop: "2em", color: "#ff3838", borderColor: "#ff3838" }} variant="outlined">WATCH MORE VIDEOS</Button></center>        
                </div>
           </div>
           <hr/>
           <div className="content-main-padding" style={{background: "#000"}}>
                <Typography variant="h4" className="heading1 font-face-AG" style={{padding: "0em", color: "#ff3838", textAlign: "center" }}>CONTACT</Typography>                        
                <Typography variant="h3" className="heading2 font-face-LB" style={{padding: "0em 0em 1em 0em", color: "#212121", textAlign: "center"}}>LET'S TALK</Typography>
                <Grid container>
                    <Grid item sm={12} md={2}></Grid>
                    <Grid item sm={12} md={8}>
                        <Paper className={classes.contactForm} elevation={2}>
                           
                            <Typography variant="body2" style={{paddingTop: "1em"}}>Get in touch with our growing community by leaving your details below. It's our initiative and our pleasure to hear your story the way you tell it. Here at 1670 Mabuhay , the future of our agents rests in their own hands - but they all had to start somewhere.</Typography>
                            <Typography variant="body2" style={{paddingTop: "1em"}}>Mold your ideal future into reality by starting your journey here with us. After all, we're only one click away.</Typography>

                           <div className={classes.learnInputs}>
                                <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Email Address" fullWidth/>
                                <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Name" fullWidth/>
                                <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Subject" fullWidth/>
                                <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" rows={6} placeholder="Message" multiline fullWidth/>
                           </div>
                           <div style={{width: "100%", padding: "1em 4em"}}>
                                <Button className="button-default uniform-style1" style={{float: "right", color: "#ff3838", borderColor: "#ff3838"}} variant="outlined">Submit</Button>
                           </div>
                        </Paper>
                    </Grid>
                </Grid>
           </div>


           {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
        </div>
    )
}




export default withRouter(Home);
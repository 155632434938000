import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import config from '../config/config';
import { AuthContext } from '../auth/AuthContext';
import StateHelper from '../services/stateHelper';
import { isEmail } from '../services/validators';
import $http from '../services/http';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useModal } from './Modal';
import Recaptcha from './Recaptcha';
import Box from '@material-ui/core/Box';

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        // flexGrow: 1,
        '& .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error': {
            color: 'white'
        }
    },
    join: {
        background: "#ff3838",
        color: "#ffffff",
        width: "100%",
        padding: '6em 8em',
        textAlign: "center"
    },
    joinText: {
        padding: "0.1em 0"
    },
    joinInput: {
        background: "#fff",
        margin: "0 0.25em"
    },
    joinButton: {
        margin: "0 0.25em",
        marginLeft: '1em',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#00bbff',
        color: '#fff',
        fontSize: '1em',
        borderRadius: '5px',
        backgroundColor: 'transparent'
    },
    joinInputs: {
        marginTop: "2em"
    },
    loading: {
        color: "#fff"
    }
}));

const InviteEmail = props => {
    const { showModal } = useModal();
    const classes = useStyles();
    const {user} = React.useContext(AuthContext);
    const [status, setStatus] = React.useState({isLoading: false, isSuccess: false, isError: false});
    const [state, setState] = React.useState({email: '', _errors: {}});

    fs.useState(state, setState);
    fs.useValidator({
        email: isEmail,
    });

    const onCheck = async () => {

        setStatus({...status, isLoading: true});

        await fs.validateStateWithWait();
        if(!fs.hasErrors() && state.email.trim() !== '') {
            showModal({
                component: Recaptcha,
                componentProps: {
                    onSubmit: onSubmit,
                    setStatus: setStatus
                }
            });
        } else {
            setStatus({isLoading: false, isSuccess: false, isError: false});
        }
    }

    const onSubmit = async (token) => {

        let data = {...state, token: token};

        if(user.user) {
            data = {
                ...data,
                r: user.user.info._id
            };
        }

        const res = await $http({
            method: 'post',
            url: config.app.server.url + `/applicants/invite`,
            data: data
        }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
            setStatus({isLoading: false, isSuccess: false, isError: true});
        });

        if(res){
            
            if(res.data.error) {
                setStatus({isLoading: false, isSuccess: false, isError: true});
                return;
            }

            if(res.data.msg) {
                setStatus({isLoading: false, isSuccess: true, isError: false});
                return;
            }
        }

        setStatus({...status, isLoading: false});
    }
    
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={6} className="join-us-container">
                    <img className={classes.teamImg} src="./assets/images/DOYOUWANTTOBEONEOFUS.png"/>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.join +' join-us-container'}>
                    <div class="join-us-content">
                    <Typography className={classes.joinText +''} variant="h4">LET'S BE ONE.</Typography>
                    <Typography className={classes.joinText} variant="body2">TYPE IN YOUR EMAIL ADDRESS AND WE'LL SEND YOU AN INVITE.</Typography>
                    <div className={classes.joinInputs}>
                        {
                            status.isLoading ?
                            <CircularProgress className={classes.loading} />
                        :
                            status.isError ?
                            <Typography style={{color: "#e80000"}} variant="subtitle2">reCaptcha token was not verified. Please try again.</Typography>
                        :
                            status.isSuccess ?
                            <Typography>Invite Successfully Sent!</Typography>
                        :
                            <React.Fragment>
                                <TextField {...fs.model(`email`)}
                                    InputProps={{ className: classes.joinInput }}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Email Address"
                                    autoComplete="off"
                                    helperText={(state._errors && state._errors["email"]) ? state._errors["email"].message : ""}
                                />
                                {
                                    state.email.trim() !== '' && !state._errors["email"] &&
                                    <Button onClick={onCheck} variant="outlined" className={classes.joinButton +' uniform-style1'}>Submit</Button>
                                }
                            </React.Fragment>
                        }
                    </div>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default InviteEmail;
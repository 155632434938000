import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Paper, TextField, Button, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import { crud } from '../services/crud';
import { withRouter } from 'react-router-dom';
import config from "../config/config";
import { format } from 'date-fns';
import StateHelper from '../services/stateHelper';
import { isRequired } from '../services/validators';
import { useModal } from '../components/Modal';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    img: {
        height: "calc(100vh - 200px)",
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    label: {

    },
    slideItem: {
    },
    text: {
        textAlign: "center",
        fontWeight: "900",
        color: "#ffffff",
        maxWidth: "80%"
    },
    carousel: {
        position: "relative"
    },
    stepperContainer: {
        position: "absolute",
        bottom: "1em",
        width: "100%",
        display: "flex",
        justifyContent: 'center'
    },
    tags:{
        display: "flex",
        padding: "0.2em 0",
        alignItems: "center"
    },
    chip: {
        margin: "0 0.2em",
        cursor: "pointer"
    },
    loginForm: {
        background: "#2a7fff",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "4em 3em",
        minHeight: "727px"
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
}));

const Webinar = (props) => {
    const { showModal, closeModal } = useModal();
    const classes = useStyles();
    const theme = useTheme();
    const [article, setArticle] = React.useState({});
    const [status, setStatus] = React.useState({isSuccess: false, isLoading: false, accepted: false, notAccepted: false});
    const [slides, setSlide] = React.useState([]);
    const art = crud('webinars');

    const [state, setState] = React.useState({});
    fs.useState(state, setState);
    fs.useValidator({
        email: isRequired,
        firstname: isRequired,
        lastname: isRequired,
        contact: isRequired
    });

    const getWebinar = async () => {
        let res = await art.findOne(props.match.params.id);
        if(res){
            if(res.data) {
                let s = [];
                for(const p of res.data.cover) {
                    s.push({
                        label: "",
                        imgPath: `${config.app.server.url}/storage/${p._id}`
                    });
                }
                setSlide(s);
                setArticle(res.data);
            }
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = slides.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const onSubmit = async () => {
        await fs.validateStateWithWait();
        if(!status.accepted) {
            setStatus({...status, notAccepted: true});
            return;
        }
        if(!fs.hasErrors()) {
            setStatus({...status, isLoading: true});
            const a = crud("participants");
            const res = await a.save({...state, webinar: article._id, webinar_: article});
            if(res) {
                if(res.data) {
                    setTimeout(()=>{
                        setStatus({...status, isSuccess: true});
                    }, 1000);
                    return;
                }
            }
        }

        setTimeout(()=>{
            setStatus({...status, isLoading: false});
        }, 1000);
    }

    const reset = () => {
        setState({});
        setStatus({isSuccess: false, isLoading: false, accepted: false, notAccepted: false});
    }

    const onAccepted = () => {
        setStatus({ ...status, ["accepted"]: true, notAccepted: false });
    }

    const handleChange = (event) => {
        if(event.target.checked) {
            showModal({
                title: 'Terms and Conditions',
                message: <div style={{minWidth: "500px"}}><Typography>
                   I agree to the Terms and Conditions on this website. I also agree to provide my name, email address and contact number for the purpose of 1670 Mabuhay Team to communicate with me additional information about 1670 Mabuhay and its activities. My personal and contact information will not be shared or distributed with a 3rd party for whatever reason.
                    </Typography></div>,
                actions: [{ title: 'Agree', action: onAccepted }, { title: 'Disagree' }]
             });
        } else {
            setStatus({ ...status, [event.target.name]: event.target.checked });
        }
    };

    React.useEffect(()=>{
        getWebinar();
    },[]);

    return (
        <div>
            <div className={classes.carousel}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {slides.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                        <div className={classes.slideItem}>
                            <div className={classes.img} style={{background: `url(${step.imgPath}) no-repeat center center / cover`}}>
                                <Typography className={classes.text} variant="h3" >{step.label}</Typography>
                            </div>
                        </div>
                        
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
            {slides.length > 1 && <div className={classes.stepperContainer}>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        style={{background: "none"}}
                        className={classes.stepper}
                    />
                </div>}
            </div>

            <div className="content-main-padding">
                <Grid container>
                    <Grid item md={7}>
                        <div style={{paddingRight: "2em"}}>
                            {article._id && 
                                    <div className="article-main-padding">
                                        <Typography variant="h4" style={{fontWeight: 700}}>{article.title}</Typography>
                                        <Typography variant="body2">{format(new Date(article.date), "MMMM dd, yyyy")}</Typography>
                                        <Typography variant="body2">Time: {format(new Date(article.time), "hh:mm a")}</Typography>
                                        <Typography style={{padding: "1.5em 0"}}>
                                            <span dangerouslySetInnerHTML={{__html: article.description.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></span>
                                        </Typography>
                                        <br/>
                                        <Typography variant="body1"><b>Platform:</b> Zoom</Typography>
                                        <Typography variant="body1"><b>Duration:</b> {article.duration}</Typography>
                                        <Typography variant="body1"><b>Zoom ID:</b> {article.zoomId}</Typography>
                                        <Typography variant="body1"><b>Zoom Passcode:</b> {article.zoomPasscode}</Typography>
                                        <Typography variant="body1"><b>Zoom Link:</b> <a href={article.zoomLink} target="_blank">{article.zoomLink}</a></Typography>
                                    </div>
                                }
                        </div>
                    </Grid>
                    <Grid item md={5}>
                        <Paper className={classes.loginForm}>
                            {status.isSuccess ?
                                    <div style={{width: "100%", height: "100%", color: "#fff", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                        <Typography variant="h5">Successfully Submitted!</Typography>
                                        {
                                        //<Typography>Insert more message here</Typography>
                                        }
                                        <Button style={{margin: "2em 0"}} onClick={reset} color="primary" variant="contained">Register Another</Button>
                                    </div>
                                :
                                <React.Fragment>
                                    <Typography variant="h5" style={{fontWeight: 700, color: "#fff", padding: "0.5em 0 1em 0", textAlign: "center"}}>Registration Form</Typography>
                                        {status.isLoading ?
                                            <div style={{textAlign: "center", padding: "5em 0", width: "100%"}}><CircularProgress color="primary" /></div>
                                            :
                                            <React.Fragment>
                                                <TextField  {...fs.model("firstname")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="First Name" helperText={(state._errors && state._errors["firstname"]) ? state._errors["firstname"].message : ""}/>
                                                <TextField  {...fs.model("lastname")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Last Name" helperText={(state._errors && state._errors["lastname"]) ? state._errors["lastname"].message : ""}/>
                                                <TextField  {...fs.model("email")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Email" helperText={(state._errors && state._errors["email"]) ? state._errors["email"].message : ""}/>
                                                <TextField  {...fs.model("contact")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Contact Number" helperText={(state._errors && state._errors["contact"]) ? state._errors["contact"].message : ""}/>
                                                <br/>
                                                <TextField multiline rows={8} {...fs.model("referred")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="How did you find out about this webinar?"/>
                                                <FormControlLabel
                                                    style ={{
                                                        color: "#fff",
                                                    }}
                                                    control={<Checkbox  
                                                                style ={{
                                                                color: "#fff",
                                                            }}
                                                            checked={status.accepted}
                                                            onChange={handleChange}
                                                            name="accepted" />}
                                                    label={<div>I agree to <b>Terms and Conditions</b></div>}
                                                />
                                                {status.notAccepted && <Typography variant="subtitle2" style={{color: "rgb(255 59 44)", fontWeight: 700}}>Agree Terms and Conditions to continue.</Typography>}
                                                <div style={{textAlign: "right", padding: "1em 0"}}><Button onClick={onSubmit} color="primary" variant="contained">Submit</Button></div>
                                            </React.Fragment>   
                                        }
                                </React.Fragment>

                            }

                        </Paper>
                    </Grid>
                </Grid>
            </div>
             {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Webinar);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import { format } from 'date-fns';
import config from '../config/config';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import StateHelper from '../services/stateHelper';
import Button from '@material-ui/core/Button';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    textfield: {
        width: "100%",
        padding: "0em 0.5em",
    },
    searchBtn: {
        margin: "0.5em 0em"
    }
}));

const Articles = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [articles, setArticles] = React.useState([]);
    const art = crud('articles');
    const [state, setState] = React.useState({});
    fs.useState(state, setState);

    const getArticles = async () => {
        let res = await art.find({
            _where: {
                _or: [{
                    title_contains: state.query,
                    description_contains: state.query
                }]
            },
            _sort: "createdAt:DESC",
            _limit: 1000
        });
        if(res){
            if(res.data) {
                //if(res.data.length > 0) {
                    setArticles(res.data);
                //}
            }
        }
    }

    const onLinkClick = (url) => {
        history.push(url);
    }

    React.useEffect(()=>{
        getArticles();
    },[]);

    return (
        <div>
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
            <div className="content-main-padding articles-page">
                <div style={{ padding: "3em 0 1em"}}>
                    <Grid container>
                        <Grid item xs={12} sm={11} md={11}>
                            <TextField  {...fs.model("query")} className={classes.textfield} variant="outlined"  size="large" placeholder=""/>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                            <Button onClick={getArticles} className={classes.searchBtn+" searchBtn"} color="primary" variant="contained">Search</Button>
                        </Grid>
                    </Grid>
                </div>
                { articles.length > 0 ? (
                    articles.map((a, idx) => (
                        <div onClick={(e)=>{onLinkClick(`/articles/${a._id}`);}} className="article-item clickable">
                            <Grid container>
                                <Grid item md={6} className="content-photo-grid-padding">
                                    <div boxShadow={2}>
                                        <img src={`${config.app.server.url}/storage/${a.photos[0]}`} width="100%" className="article-item-photo" ></img>
                                    </div>
                                </Grid>
                                <Grid item md={6} style={{padding: "3em 3em 3em 1em"}}>
                                    <a className="article-link" href={`/articles/${a._id}`} onClick={(e)=>{e.preventDefault();}}><Typography variant="h5" style={{fontWeight: 700}}>{a.title}</Typography></a>
                                    <Typography variant="body2">{format(new Date(a.createdAt), "MMMM dd, yyyy")}</Typography>
                                    <Typography variant="body2">By: {a.author}</Typography>
                                    <br/>
                                    <Typography variant="body2">{a.body.substring(0, 500)}...
                                    <br/>
                                    <br/>
                                    <b>Read More</b></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))
                ) : (
                
                    <Typography
                        variant="h5"
                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                    >
                        No Articles Found.
                    </Typography>
                )}
            </div>
        </div>
    )
}

export default withRouter(Articles);
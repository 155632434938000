import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import { format } from 'date-fns';
import config from '../config/config';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import StateHelper from '../services/stateHelper';
import Button from '@material-ui/core/Button';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    textfield: {
        width: "100%",
        padding: "0em 0.5em"
    },
    searchBtn: {
        margin: "0.5em 0em"
    }
}));

const Trainings = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [videos, setVideos] = React.useState([]);
    const art = crud('trainings');
    const [state, setState] = React.useState({});
    fs.useState(state, setState);

    const getVideos = async () => {
        let res = await art.find({
            title_contains: state.query,
            description_contains: state.query,
            _sort: "createdAt:DESC", 
            _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    setVideos(res.data);
                }
            }
        }
    }

    const onLinkClick = (url) => {
        history.push(url);
    }

    const YoutubeEmbedded = (p) => {
        const matches =  p.link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        console.log(matches);
        return (
            <div className="video-container">
                {(matches && matches.length > 0) && <iframe
                    className="responsive-iframe"
                    src={`https://www.youtube.com/embed/${matches[1]}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />}
            </div>
        )
    }

    React.useEffect(()=>{
        getVideos();
    },[]);

    return (
        <div>
            {/* <pre>{JSON.stringify(videos, null, 4)}</pre> */}
            
            <div className="content-main-padding">
                <div>
                    <Grid container>
                        <Grid item md={11}>
                            <TextField  {...fs.model("query")} className={classes.textfield} variant="outlined" size="large" placeholder=""/>
                        </Grid>
                        <Grid item md={1}>
                            <Button onClick={getVideos} className={classes.searchBtn+" searchBtn"} color="primary" variant="contained">Search</Button>
                        </Grid>
                    </Grid>
                </div>
                {(
                    videos.map((v, idx) => (
                        <div className="article-item">
                            <Grid container>
                                <Grid item md={6} className="content-video-grid-padding">
                                    <Box boxShadow={2}>
                                        <YoutubeEmbedded link={v.hyperlink} />
                                    </Box>
                                </Grid>
                                <Grid item md={6} style={{padding: "3em 3em 3em 1em"}}>
                                    <Typography variant="h5" style={{fontWeight: 700}}>{v.title}</Typography>
                                    <br/>
                                    <Typography variant="body2" >{v.description}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default withRouter(Trainings);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        padding: "5em 5em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 108px)",
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    joinInputs: {
        width: "400px"
    }
}));

const NotFound = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
            <div className={classes.content}>
                <Grid container>
                    <Grid sm={12} md={6} item style={{textAlign: "right", padding: "1em 3em", borderRight: "solid 1px #fff"}}>
                        <Typography variant="h1">PAGE NOT FOUND.</Typography>
                        <Typography>The page you are looking for does not exist.</Typography>
                        <Typography>You can click the button below to go back to the homepage.</Typography>
                        <div style={{textAlign: "right", padding: "1em 0"}}><Button color="primary" href="/#/home" variant="contained">Home</Button></div>
                    </Grid>
                    <Grid sm={12} md={6} item style={{padding: "1em 3em"}}>
                        <div className={classes.joinInputs}>
                            <img style={{maxHeight: "400px"}} src="./assets/images/404-error.png"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default NotFound;
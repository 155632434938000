import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Topbar from './components/topbar';
import grey from '@material-ui/core/colors/grey';
import {withRouter} from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import Footer from './components/Footer';
import { AuthContext } from '../../auth/AuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
    }
  },
  appBar: {
      zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('lg')]: {
      padding: "0 90px"
    }
  },
  content: {
    flexGrow: 1,
    marginTop: "5.5em",
    minHeight: "calc(100vh - 80px)",
    minWidth: "100vw"
  },
  title: {
    color: grey[50]
  },
  logo: {
    height: "80px"
  },
  footer: {
    background: orange[700],
    color: "#fff",
    width: "100%",
    padding: "3em 6em"
  },
  profile:{
    display: "flex",
    margin: "0 0.2em",
    padding: "0.75em",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: orange[700],
      color: "#fff"
    }
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menuIcon: {
    color: "#fff",
    fontSize: "40px"
  },
  menuItem: {
    minWidth: "160px"
  }
}));

function DefaultLayout(props) {
  const {children, location, history} = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, logout } = React.useContext(AuthContext);
  const [header, setHeader] = useState("header")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

    const listenScrollEvent = (event) => {
      if (window.scrollY < 73) {
        return setHeader("bg-transparent")
      } else if (window.scrollY > 70) {
        return setHeader("bg-grey")
      } 
    }
    
    React.useEffect(() => {
      window.addEventListener('scroll', listenScrollEvent);
    
      return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history]);

  const appBarItems = [
    //{title: "Home", label: "Home", href: "/home"},
    {title: "About Us", label: "About Us", href: "/team"},
    {title: "Products", label: "Products", href: "/products"},
    {title: "Testimonials", label: "Testimonials", href: "/testimonials"},
    {title: "Media", label: "Media", href: "/videos"},
    // {title: "Blog", label: "Blog", href: "/blog"},
    //{title: "Lets Talk", label: "Lets Talk", href: "/webinars"}
  ];

  const appBarItemsMobile = [
    //{title: "Home", label: "Home", href: "/home"},
    {title: "About Us", label: "About Us", href: "/team"},
    {title: "Products", label: "Products", href: "/products"},
    {title: "Testimonials", label: "Testimonials", href: "/testimonials"},
    {title: "Media", label: "Media", href: "/videos"},
    // {title: "Blog", label: "Blog", href: "/blog"},
    //{title: "Lets Talk", label: "Lets Talk", href: "/webinars"},
    {title: "Login", label: "Login", href: "/login"}
  ];

  const linkOnClick = (route) => {
    history.push(`${route}`);
  }

  const ifActive = (href) => {
    return location.pathname.startsWith(href);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar+' '+ header} color="default">
        <Toolbar className={classes.toolbar}>
          <a className={"logo-container"} href="/"><img className={classes.logo } src="./assets/images/logo.png"/></a>
          <Hidden mdDown>
            {
              appBarItems.map((item, idx)=>(
                <a key={idx} className={ifActive(item.href) ? "appbarlink current" : "appbarlink"} href={item.href} onClick={(e)=>{ e.preventDefault(); linkOnClick(item.href);}} title={item.title}>{item.label.toUpperCase()}</a>
              ))
            }
          <div style={{flexGrow: 1}}></div>
            <a key={8} className={ifActive("/login") ? "appbarlink current" : "appbarlink"} href={"/login"} onClick={(e)=>{ e.preventDefault(); linkOnClick("/login");}} title="Login">LOGIN</a>
            <Button href="/apply-now" onClick={(e)=>{ e.preventDefault(); linkOnClick("/apply-now");}} disableElevation style={{marginLeft: '1em', borderWidth: "2px", color: "#fff"}} variant="outlined" color="primary" href="#" title="Login" size="large">Join Now</Button>
          </Hidden>
          <Hidden lgUp>
            
          <div style={{flexGrow: 1}}></div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon className={classes.menuIcon}/>
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden lgUp>
        <nav className={classes.drawer}>
          <Topbar open={mobileOpen} variant="temporary" onClose={handleDrawerToggle} pages={appBarItemsMobile}/>
        </nav>
      </Hidden>
      <main className={classes.content}>
            {children}
      </main>
      <Footer />
    </div>
  );
}

export default withRouter(DefaultLayout);

export default {
    app: {
      id: 'fwd-marketing',
      server: {
        url: 'https://1670mabuhay.com'
      },
      portal: {
        url: "https://1670mabuhay.com"
      },
      agent:{
        url: "https://portal.1670mabuhay.com"
      }
    },
    reCaptcha: {
      siteKey: "6Lc33zQqAAAAAONs6s8-IZXvCufdbRpWq1hU-5Cv",
      secretKey: "6Lc33zQqAAAAAMQr1DX3J0GDnPya_hno8t5o-d5c",
      theme: "light"
  }
}

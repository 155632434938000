import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar";
import { crud } from '../services/crud';
import { AuthContext } from '../auth/AuthContext';
import { format, differenceInDays } from 'date-fns';
import DoneIcon from '@material-ui/icons/Done';
import $http from '../services/http';
import config from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        minHeight: "calc(100vh - 80px)",
        padding: "4.5em 13%"
    },
    grid: {
        margin: "2em 0"
    },
    gridItem: {
        padding: "0.75em 0",
        textTransform: "capitalize"
    },
    avatarGrid: {
        display: "flex",
        alignItems: "center",
        flexFlow: "wrap",
        padding: "0 5px"
    },
    avatar: {
        margin: "0.2em",
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: "#fff"
    },
    avatarDone: {
        margin: "0 0.2em",
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: green[700]
    },
    avatarFail: {
        margin: "0 0.2em",
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: "#f44336"
    },
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    badge: {
        marginLeft: "1.5em"
    },
    searchInput: {
        background: "#ffffff",
        margin: "5px 0"
    }
}));

const ApplicantItem = (props) => {
    const {onAccept, app, onOpen} = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid md={3}>
                <a href={`/requirements/${app._id}`} onClick={(e)=>{e.preventDefault(); onOpen(app._id)}} style={{color: "#fff"}}>
                    <Typography className={classes.gridItem}>{`${app.lastname}, ${app.firstname}`}</Typography>
                </a>
            </Grid>
            
            <Grid md={6} className={classes.avatarGrid}>
                {
                    isLoading ? 
                        <CircularProgress size={25}/>
                    :
                    <React.Fragment>
                        <Button
                            style={{margin: "0 0.2em"}}
                            color="primary"
                            onClick={()=>{setIsLoading(true); onAccept(app._id)}}
                        >
                            Accept
                        </Button>
                        <Button
                            style={{margin: "0 0.2em"}}
                            color="secondary"
                        >
                            Decline
                        </Button>
                    </React.Fragment>
                }
            </Grid>
            <Grid md={3}>
            </Grid>
        </React.Fragment>
    )
}

const Requirements = (props) => {
    const { history } = props;
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [me, setMe] = React.useState({...user.user.info, requirements: []});
    const [applicants, setApplicants] = React.useState([]);
    const [requirements, setRequirements] = React.useState({layout: []});
    const a = crud('applicants/members');

    const getApplicants = async (key = null) => {

        const doc = {leader: user.user.info._id, _sort: "lastname:ASC"};
        if(key) {
            doc["or:firstname_regex"] = key;
            doc["or:lastname_regex"] = key
        }

        const res = await a.find(doc);
        if(res){
            if(res.data){
                setApplicants(res.data);
            }
        }
    }

    const onSearchChange = (e) => {
        getApplicants(e.target.value);
    }

    const retrieve = async () => {
        const s = crud('settings');
        const res = await s.find({
          key: 'requirements',
        });

        const _layout = res.data[0].json.layout.sort((la, lb)=>{
            if(la.y > lb.y) {
                return 1;
            }
            return -1;
        }).sort((la, lb)=> {
            if(la.y == lb.y) {
                if(la.x > lb.x) {
                    return 1;
                }
                return -1;
            }
        });

        const l = {
            i: "agent-code",
            x: _layout.length % 3,
            // x: 4,
            y: Infinity,
            w: 1,
            h: 5,
        };
        _layout.push(l);

        const agentCode = 
        {
            title: "FWD Agent Code",
            description: "A FWD Agent Code will be assigned to you upon completion of the requirements and passing the IC Exams.",
            details: "Please input your Agent Code below.",
            type: "text",
            type_: {
                label: "Text",
                value: "text"
            }
        };
        
        const _state = res.data[0].json.state;
        _state['agent-code'] = agentCode;
    
        if (res) {
          if (res.data.length > 0) {
            setRequirements({layout: [..._layout], state: {..._state}});
          }
        }
    };

    const AcceptApplicants = async (id) => {
        const res = await $http.post(config.app.server.url + `/applicants/accept/${id}`);
        getApplicants();
    }

    const openRequirements = (id) => {
        history.push(`/requirements/${id}`);
    }

    const getMyRequirements = async () => {
        const r = crud('requirements');
        const res = await r.find({"oid:applicant": me._id});
        if(res){
            if(res.data){
                if(res.data.length > 0) {
                    const reqs = {};
                    for(const re of res.data) {
                        reqs[re.requirementId] = re.status;
                    }
                    setMe({...me, requirements: reqs});
                }
            }
        }
    }

    const getDateDiff = (date) => {
        return differenceInDays(new Date(), new Date(date))
    }

    React.useEffect(()=>{
        getMyRequirements();
        getApplicants();
        retrieve();
    },[]);

    return (
        <div className={classes.root} style={{padding: "1em"}}>
            {/* <pre>{JSON.stringify(me.dateStarted, null, 4)}</pre> */}
            <div className={classes.content}>
               <div>
                    <Typography variant="h5">Requirements</Typography>
                    <Typography>These are the requirements to be encoded as a Financial Wealth Planner. Download the forms needed and upload them here after filling them up.
<br/>
<br/>
CLICK ON YOUR NAME TO GET STARTED!
</Typography>
               </div>
               <div>
                   <TextField
                        InputProps={{ className: classes.searchInput, endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ) }}
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        autoComplete="off"
                        onChange={onSearchChange}
                    />
                </div>
               <div style={{ overflowX: "auto" }}>
                   <Grid className={classes.grid} container style={{minWidth: "550px", overflowX: "auto", width: "100%"}}>
                        <Grid xs={3} sm={3} md={3} item>
                            <Typography style={{fontWeight: "600"}}>Name</Typography>
                        </Grid>
                        <Grid xs={6} sm={6} md={6} item>
                            <Typography style={{fontWeight: "600", padding: '0 5px'}}>Progress</Typography>
                        </Grid>
                        <Grid xs={3} sm={3} md={3} item>
                            <Typography style={{fontWeight: "600"}}>Date Started</Typography>
                        </Grid>
                        <React.Fragment>
                                    <Grid item xs={3} sm={3} md={3}>
                                        <a href={`/requirements/me`} onClick={(e)=>{e.preventDefault(); openRequirements("me")}} className={classes.nameContainer} style={{color: "#fff"}}>
                                            <Typography className={classes.gridItem}>{`${me.lastname}, ${me.firstname}`}</Typography>
                                            <Badge className={classes.badge} color="primary" badgeContent={'ME'}/>
                                        </a>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} className={classes.avatarGrid}>
                                        {(
                                            requirements.layout.filter(r => r.i !== "n1").map((r, idx) => (
                                                <div>
                                                    {
                                                        !me.requirements[r.i] ?
                                                        <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                            <Avatar key={idx} className={classes.avatar}>
                                                                <FiberManualRecordIcon fontSize="small"/>
                                                            </Avatar>
                                                        </Tooltip>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                me.requirements[r.i] == "confirmed" &&
                                                                <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                                    <Avatar key={idx} className={classes.avatarDone}>
                                                                        <DoneIcon fontSize="small"/>
                                                                    </Avatar>
                                                                </Tooltip>
                                                            }
                                                            {
                                                                me.requirements[r.i] == "pending" &&
                                                                <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                                    <Avatar key={idx} className={classes.avatarFail}>
                                                                        <FiberManualRecordIcon style={{color: "#f44336"}} fontSize="small"/>
                                                                    </Avatar>
                                                                </Tooltip>
                                                            }
                                                        </React.Fragment>
                                                    }             
                                                </div>
                                            ))
                                        )}
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3}>
                                        {me.dateStarted && <Typography className={classes.gridItem}>{format(new Date(me.dateStarted), "MMMM dd, yyyy")} ({getDateDiff(me.dateStarted)})</Typography>}
                                    </Grid>
                                </React.Fragment>
                        {(
                            applicants.map((app, idx)=>(
                                app.status == "applicant" ?
                                <React.Fragment key={idx}>
                                    <ApplicantItem app={app} onOpen={openRequirements} onAccept={AcceptApplicants} />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Grid item xs={3} sm={3} md={3}>
                                        <a href={`/requirements/${app._id}`} onClick={(e)=>{e.preventDefault(); openRequirements(app._id)}} style={{color: "#fff"}}><Typography className={classes.gridItem}>{`${app.lastname}, ${app.firstname}`}</Typography></a>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} className={classes.avatarGrid}>
                                        {(
                                            requirements.layout.filter(r => r.i !== "n1").map((r, idx) => (
                                                <div>
                                                    {
                                                        !app.requirements[r.i] ?
                                                        <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                            <Avatar key={idx} className={classes.avatar}>
                                                                <FiberManualRecordIcon fontSize="small"/>
                                                            </Avatar>
                                                        </Tooltip>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                app.requirements[r.i] == "confirmed" &&
                                                                <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                                    <Avatar key={idx} className={classes.avatarDone}>
                                                                        <DoneIcon fontSize="small"/>
                                                                    </Avatar>
                                                                </Tooltip>
                                                            }
                                                            {
                                                                app.requirements[r.i] == "pending" &&
                                                                <Tooltip title={requirements.state[r.i].title} aria-label="add">
                                                                    <Avatar key={idx} className={classes.avatarFail}>
                                                                        <FiberManualRecordIcon style={{color: "#f44336"}} fontSize="small"/>
                                                                    </Avatar>
                                                                </Tooltip>
                                                            }
                                                        </React.Fragment>
                                                    }             
                                                </div>
                                            ))
                                        )}
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3}>
                                        <Typography className={classes.gridItem}>{format(new Date(app.dateStarted), "MMMM dd, yyyy")} ({getDateDiff(app.dateStarted)})</Typography>
                                    </Grid>
                                </React.Fragment>

                            ))
                        )}
                   </Grid>
               </div>
            </div>
        </div>
    )
}

export default withRouter(Requirements);
import './App.css';
import {
  HashRouter as Router,
} from "react-router-dom";
import Routes from "./Routes";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import pallete from "./theme/pallete";
import { AuthProvider } from "./auth/AuthContext";
import { ApplicantProvider } from "./stores/ApplicantStore";
import { StoreProvider as ModalProvider } from './components/Modal';

const theme = createMuiTheme({
  overrides: {
    MuiMobileStepper: {
      dot: {
        backgroundColor: "#fff",
      },
    },
  },
  palette: pallete,
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <AuthProvider>
          <ApplicantProvider>
            <Router>
              <Routes />
            </Router>
          </ApplicantProvider>
        </AuthProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;

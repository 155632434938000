import React, { forwardRef } from 'react';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, ListItemText, Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0
  },
  button: {
    color: "#FFF",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontFamily: "Poppins"
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  nested: {
    color: colors.blueGrey[800],
    width: '100%',
    paddingLeft: "1em", 
    fontSize: "0.875em",
    fontWeight: "500",
    fontFamily: "Poppins"
  },
  mobilePanel: {
    backgroundColor: "rgba(0,0,0,0.99)",
    padding: "16px"
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const TopbarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.mobilePanel}>
      <List
        {...rest}
        className={clsx(classes.root, className)}
      >
        {pages.map(page => (
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
          >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              {page.label}
            </Button>
          </ListItem>
        ))}
      </List>
      <Button href="/apply-now" onClick={(e)=>{e.preventDefault(); props.history.push("/apply-now")}} disableElevation style={{margin: '0em 0 1em'}} variant="contained" color="primary" href="#" title="Login" size="large">Join Now</Button>
    </div>
  );
};

TopbarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default withRouter(TopbarNav);
import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import TopbarNav from './TopnavBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => (
  {
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  close: {
    margin: "auto",
    display: "block"
  }
}));

const Topbar = props => {
  const { open, pages, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const closeNav = () => {
    setTimeout(()=>{
      onClose();
    }, 300);
  }

  return (
    <Fragment>
            <Drawer
              anchor="right"
              classes={{ paper: "appbar-drawer" }}
              onClose={onClose}
              open={open}
              variant={variant === 'responsive' ? 'temporary' : variant}
            >
              <div
                {...rest}
                className={clsx(classes.root, className)}
              >
                <TopbarNav
                  className={classes.nav}
                  pages={pages}
                  name={props.name}
                  onLogout={props.onLogout}
                  isAgent={props.isAgent}
                />
                <div style={{position: "absolute", right: "10px", top: "5px"}}>
                    <IconButton className={classes.close} color="primary" aria-label="Close Navbar" onClick={closeNav}>
                      <CloseIcon />
                    </IconButton>
                  </div>
              </div>
            </Drawer>
    </Fragment>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Topbar;
import React from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { crud } from "../services/crud";
import config from '../config/config';
import { format } from 'date-fns';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        height: "calc(100vh - 300px)",
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "url('./assets/images/webinar1.jpg') no-repeat center center / cover"
    },
    banner2: {
        height: "calc(100vh - 300px)",
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "url('./assets/images/webinar2.jpg') no-repeat center center / cover"
    },
    team: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 2em"
    },
    teamImg: {
        height: "375px",
        width: '100%'
    }
}));


const Webinars = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [webinars, setWebinars] = React.useState({pathfinder: [], torch: []});
    const _team = crud('webinars/active');

    const getWebinars = async () => {
        let res = await _team.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    const s = {pathfinder: [], torch: []};
                    for(const a of res.data) {
                        s[a.category].push({
                            img: `${config.app.server.url}/storage/${a.cover[0]}`,
                            id: a._id,
                            title: a.title,
                            date: format(new Date(a.date), "MMMM dd, yyyy"),
                            time: format(new Date(a.time), "hh:mm a"),
                        });
                    }
                    setWebinars(s);
                }
            }
        }
    }

    const onLinkClick = (url) => {
        props.history.push(url);
    }

    React.useEffect(()=>{
        getWebinars();
    },[]);

    return (
        <div>
            <div>
                <div className={classes.banner}></div>
            </div>
            <div className="content-main-padding">
                <Typography variant="h3" style={{fontWeight: 700}}>
                    PATHFINDER WEBINARS
                </Typography>
                <br/>
                <Typography>
                    This will be the description regarding what a Pathfinder webinar will be dealing with.
                </Typography>
                <br/>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Eu consequat ac felis donec et odio pellentesque diam volutpat. Rhoncus urna neque viverra justo nec. Non nisi est sit amet facilisis. Sit amet nisl purus in mollis nunc sed. Porta lorem mollis aliquam ut porttitor leo. Natoque penatibus et magnis dis parturient montes. Morbi tristique senectus et netus et. Adipiscing bibendum est ultricies integer quis. Fames ac turpis egestas integer eget aliquet nibh praesent. Faucibus a pellentesque sit amet. Suspendisse ultrices gravida dictum fusce ut placerat orci. Morbi tristique senectus et netus et malesuada fames ac. Proin sed libero enim sed faucibus turpis in eu mi. Tincidunt lobortis feugiat vivamus at augue eget arcu.
                </Typography>

                <div style={{padding: "2em 0"}}>
                    <Grid container>
                        {(webinars.pathfinder.map((p, idx)=>(
                            <Grid className="article-item" onClick={()=>{onLinkClick(`/webinars/${p.id}`)}} key={idx} item xs={12} sm={12} md={4} style={{padding: "2em", cursor: "pointer"}}>
                                <div style={{background: `url(${p.img}) no-repeat center center / cover`, width: "100%", height: "300px"}}> </div>
                                <Typography variant="h6" style={{paddingTop: "1em"}}>{p.title}</Typography>
                                <Typography variant="h6">{p.date}</Typography>
                                <Typography variant="h6">{p.time}</Typography>
                                <Typography>
                                    {p.text}
                                </Typography>
                            </Grid>
                        ))

                        )}
                    </Grid>
                </div>
            </div>
            <div>
                <div className={classes.banner2}></div>
            </div>
            <div className="content-main-padding">
                <Typography variant="h3" style={{fontWeight: 700}}>
                    TORCH WEBINARS
                </Typography>
                <br/>
                <Typography>
                    This will be the description regarding what a Torch webinar will be dealing with.
                </Typography>
                <br/>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Eu consequat ac felis donec et odio pellentesque diam volutpat. Rhoncus urna neque viverra justo nec. Non nisi est sit amet facilisis. Sit amet nisl purus in mollis nunc sed. Porta lorem mollis aliquam ut porttitor leo. Natoque penatibus et magnis dis parturient montes. Morbi tristique senectus et netus et. Adipiscing bibendum est ultricies integer quis. Fames ac turpis egestas integer eget aliquet nibh praesent. Faucibus a pellentesque sit amet. Suspendisse ultrices gravida dictum fusce ut placerat orci. Morbi tristique senectus et netus et malesuada fames ac. Proin sed libero enim sed faucibus turpis in eu mi. Tincidunt lobortis feugiat vivamus at augue eget arcu.
                </Typography>

                <div style={{padding: "2em 0"}}>
                    <Grid container>
                        {(webinars.torch.map((p, idx)=>(
                            <Grid className="article-item" onClick={()=>{onLinkClick(`/webinars/${p.id}`)}} key={idx} item xs={12} sm={12} md={4} style={{padding: "2em", cursor: "pointer"}}>
                                <div style={{background: `url(${p.img}) no-repeat center center / cover`, width: "100%", height: "300px"}}> </div>
                                <Typography variant="h6" style={{paddingTop: "1em"}}>{p.title}</Typography>
                                <Typography variant="h6">{p.date}</Typography>
                                <Typography variant="h6">{p.time}</Typography>
                                <Typography>
                                    {p.text}
                                </Typography>
                            </Grid>
                        ))

                        )}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Webinars);
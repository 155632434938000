import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { withRouter } from 'react-router-dom';

const Logout = (props) => {
    const { logout } = useContext(AuthContext);

    const onLogout = () => {
        logout();
        props.history.replace('/');
    }

    useEffect(()=>{
        onLogout();
    },[]);

    return (
        <></>
    )
}

export default withRouter(Logout);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import { format } from 'date-fns';
import config from '../config/config';
import { withRouter } from 'react-router-dom';
import { ApplicantContext } from '../stores/ApplicantStore';
import TextField from '@material-ui/core/TextField';
import StateHelper from '../services/stateHelper';
import { MenuItem, Button} from '@material-ui/core';
import { isRequired } from '../services/validators';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import qs from 'qs';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        background: "#000",
        color: "#fff"
    },
    input: {
        background: "#fff"
    },
    labels: {
        padding: "0.25em 0"
    },
    containers: {
        padding: "0.4em 0",
        width: "100%",
        maxWidth: "550px"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    }
}));

const renderInput = (props) => (
    <TextField
        {...props}
        size="small"
        InputProps={{ ...props.InputProps, style: {background: '#fff', maxWidth: "550px"} }}
    />
);

const PersonalDetails = (props) => {
    const { history } = props;
    const classes = useStyles();
    const {applicant, setApplicant} = React.useContext(ApplicantContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [state, setState] = React.useState(
    {
        firstname: applicant.firstname,
        lastname: applicant.lastname,
        email: applicant.email,
        status: "applicant",
        status_: {
            label: "Applicant",
            value: "applicant"
        },
        birthday: null
    });

    fs.useState(state, setState);
    fs.useValidator({
        firstname: isRequired,
        lastname: isRequired,
        email: isRequired,
        contact: isRequired,
        address: isRequired,
        prefix: isRequired,
    });

    const options = [
        { label: 'Mr.', value: 'mr' },
        { label: 'Mrs.', value: 'mrs' },
        { label: 'Ms.', value: 'ms' },
        { label: 'Dr.', value: 'dr' },
        { label: 'Atty.', value: 'atty' },
        { label: 'Engr.', value: 'engr' },
        { label: 'Fr.', value: 'fr' },
        { label: 'Gen.', value: 'gen' },
        { label: 'Hon.', value: 'hon' },
    ];

    const onSubmit = async () => {
        const r = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        await fs.validateStateWithWait();
        if(!fs.hasErrors()) {
            setApplicant(state);
            if(r.r) {
                history.push(`/questionnaire?r=${r.r}`);
            } else {
                history.push("/questionnaire");
            }
        }
    }

    const handleDateChange = async (e, model) => {
        if(e) {
            const val = e || "";
            fs.validate(model, val);
            fs.setState({
                [model]: val
            })
        }
    }

    const handleChangeObject = (event, model) => {
        const idx = options.findIndex((o) => o.value === event.target.value);
        fs.validate(model, event.target.value);
        fs.setState({
          [`${model}`]: event.target.value,
          [`${model}_`]: options[idx],
        });
      };

    return (
        <div>
            <div className={classes.banner}>
                <div className="content-main-padding">
                    <Grid container style={{width: "100%"}}>
                        <Grid item md={12} style={{padding: "1em"}}>
                            <div>
                                <Typography variant="h5">
                                    Personal Details
                                </Typography>
                                <Typography variant="body2">
                                    Be part of our team.
                                </Typography>
                                <Typography variant="body2">
                                    Please answer as truthfully as possible.
                                </Typography>
                                <br/>
                            </div>
                            <div className={classes.container}>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Prefix *</Typography>
                                    <TextField
                                        InputProps={{className: classes.input}}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => handleChangeObject(e, 'prefix')}
                                        value={state.type}
                                        select
                                        size="small"
                                        helperText={(state._errors && state._errors["prefix"]) ? state._errors["prefix"].message : ""}
                                        >
                                        {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                        </TextField>
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">First Name *</Typography>
                                    <TextField 
                                        {...fs.model("firstname")}
                                        InputProps={{className: classes.input}}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        helperText={(state._errors && state._errors["firstname"]) ? state._errors["firstname"].message : ""}
                                    />
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Middle Name</Typography>
                                    <TextField {...fs.model("middlename")}  InputProps={{className: classes.input}} variant="outlined" size="small" fullWidth/>
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Last Name *</Typography>
                                    <TextField 
                                        {...fs.model("lastname")}
                                        InputProps={{className: classes.input}}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        helperText={(state._errors && state._errors["lastname"]) ? state._errors["lastname"].message : ""}
                                    />
                                </div>
                                {/* <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Date of Birth</Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableFuture
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            value={state.birthday}
                                            InputAdornmentProps={{ position: "end" }}
                                            onChange={(evt)=>{handleDateChange(evt, 'birthday')}}
                                            placeholder="Date of Birth"
                                            TextFieldComponent={renderInput}
                                            emptyLabel="MM/DD/YYYY"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                </div> */}
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Email Address *</Typography>
                                    <TextField {...fs.model("email")} InputProps={{className: classes.input}} variant="outlined" size="small" fullWidth disabled/>
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Contact Number *</Typography>
                                    <TextField
                                        {...fs.model("contact")}
                                        InputProps={{className: classes.input}}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        helperText={(state._errors && state._errors["contact"]) ? state._errors["contact"].message : ""}
                                    />
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Alternate Contact Number</Typography>
                                    <TextField {...fs.model("alternateContact")} InputProps={{className: classes.input}} variant="outlined" size="small" fullWidth/>
                                </div>
                                <div className={classes.containers}>
                                    <Typography className={classes.labels} variant="body2">Current Address (City, Barangay) *</Typography>
                                    <TextField
                                        {...fs.model("address")} 
                                        multiline rows={8}
                                        InputProps={{className: classes.input}}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        helperText={(state._errors && state._errors["address"]) ? state._errors["address"].message : ""}
                                    />
                                </div>
                                <div className={classes.containers}>
                                    {
                                        isLoading ? 
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <CircularProgress color="primary" />
                                                <Typography style={{margin: "0 1em"}}>Submitting...</Typography>
                                            </div>
                                        :
                                        <Button onClick={onSubmit} color="primary" variant="contained" disabled={state._errors && Object.keys(state._errors).length > 0}>Next</Button>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(PersonalDetails);
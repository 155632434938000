import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { crud } from '../services/crud';
import { withRouter } from 'react-router-dom';
import { ApplicantContext } from '../stores/ApplicantStore';
import TextField from '@material-ui/core/TextField';
import StateHelper from '../services/stateHelper';
import { RadioGroup, Radio, FormControlLabel, Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import qs from 'qs';
import Recaptcha from '../components/Recaptcha';
import { useModal } from '../components/Modal';

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        background: "#000",
        color: "#fff"
    },
    input: {
        background: "#fff"
    },
    labels: {
        padding: "0.5em 0"
    },
    containers: {
        padding: "1.25em 0",
        width: "100%",
        maxWidth: "650px"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    radioButton: {
        color: '#fff'
    }
}));

const Questionnaire = (props) => {
    const { showModal } = useModal();
    const { history } = props;
    const classes = useStyles();
    const {applicant, setApplicant} = React.useContext(ApplicantContext);
    const [status, setStatus] = React.useState({isLoading: false, isSuccess: false, isError: false, haveErrors: false});
    const [state, setState] = React.useState({});
    const [layout, setLayout] = React.useState({layout: []});
    const [errors, setErrors] = React.useState(false);

    fs.useState(state, setState);

    const onSubmit = async (token) => {
        await fs.validateStateWithWait();
        if(!fs.hasErrors()) {
            let hasErrors = false;
            let c = crud("applicants");
            const res = await c.save({...applicant, referredBy: state["343f64e6-6cfa-4a62-8da5-57c2bf4655ab"], token: token})
            .catch(e => {
               hasErrors = true;
               if(e.data.error) {
                setStatus({...status, isLoading: false, isError: true});
               }
            });

            if(res) {
                if(res.data) {
                    setApplicant({});
                }
            }

            if(!hasErrors) {
                c = crud("answers");
                const r = await c.save({applicant: res.data._id, state: {...state}, applicant_: {...res.data}})
                .catch(e => {
                    hasErrors = true;
                });
            }

            if(!hasErrors) {
                history.push("/completed");
                return;
            }

            console.log("Something went wrong!");
            setStatus({...status, isLoading: false, haveErrors: true});
        }
    }

    const handleChange = (event, model) => {
        const val = event.target.value;
        fs.setState({
            [model]: val
        });
    };

    const retrieve = async () => {
        const s = crud('settings');
        const res = await s.find({
          key: 'questionnaire',
        });
    
        if (res) {
          if (res.data.length > 0) {
              const _layout = res.data[0].json.layout.sort((la, lb)=>{
                  if(la.y > lb.y) {
                      return 1;
                  }
                  return -1;
              });

            setLayout({layout: [..._layout], state: {...res.data[0].json.state}});
          }
        }
        getReferrer();
    };

    const linearOptions = (s) => {
        const os = [];
        for(var i = s.from; i <= s.to; i++) {
            os.push(i);
        }

        return os;
    }

    const getReferrer = async () => {
        const r = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        if(r.r === undefined) {
            return;
        }
        const a = crud("applicants/referrer");
        const res = await a.find({r: r.r});
        if(res) {
            if(res.data) {
                if(res.data.referrer) {
                    handleChange({target:{value: res.data.referrer}}, "343f64e6-6cfa-4a62-8da5-57c2bf4655ab");
                    setApplicant({...applicant, referredBy: res.data.referrer});
                }
            }
        }
    }

    const checkRequired = () => {
        const fields = {};
        Object.keys(layout.state).forEach(s => {

            if(s == "n1") {
                return;
            }

            if(s == "_errors") {
                return;
            }

            if(!layout.state[s].question) {
                return;
            }

            if(layout.state[s].question.includes("1670")) {
                return;
            }

            if(state[s]) {
                if(state[s].trim() == '') {
                    fields[s] = true;
                }
            } else {
                fields[s] = true;
            }
        });

        setErrors({...fields});

        if(Object.keys(fields).length == 0) {
            setStatus({isLoading: true, isSuccess: false, isError: false});
            showModal({
                component: Recaptcha,
                componentProps: {
                    onSubmit: onSubmit,
                    setStatus: setStatus
                }
            });
        }
    }


    React.useEffect(()=>{
        retrieve();
    }, []);

    return (
        <div>
            <div className={classes.banner}>
                <div className="content-main-padding">
                    <Grid container style={{width: "100%"}}>
                        <Grid item md={12} style={{padding: "1em"}}>
                            <div>
                                <Typography variant="h5">
                                    Questionnaire
                                </Typography>
                                <Typography variant="body2">
                                    Be part of our team.
                                </Typography>
                                <Typography variant="body2">
                                    Please answer as truthfully as possible.
                                </Typography>
                                <br/>
                            </div>                              
                               
                            <div className={classes.layout}>
                                {layout.layout.map(l => (
                                <div data-grid={l} key={l.i} style={{ cursor: 'grab', display: "flex", justifyContent: "center", overflowY: "auto"}}>
                                    <div className={classes.containers} elevation={3}>
                                        <Typography className={classes.labels} variant="body2">{layout.state[l.i].question}</Typography>
                                        {layout.state[l.i].type == 'multiple-choice' &&
                                            <RadioGroup aria-label="gender" value={state[l.i]} onChange={e=>{handleChange(e, l.i)}}>
                                                {layout.state[l.i].options.map((o, idx) =>(
                                                    <FormControlLabel key={idx} value={o.value} control={(<Radio className={classes.radioButton}  />)} label={<Typography variant="body2">{o.label}</Typography>} />
                                                ))
                                                }
                                            </RadioGroup>   
                                        }

                                        {layout.state[l.i].type == 'short-answer' &&
                                            <TextField {...fs.model(`${l.i}`)} InputProps={{className: classes.input}} variant="outlined" size="small" fullWidth/>
                                        }

                                        {layout.state[l.i].type == 'paragraph' &&
                                            <TextField {...fs.model(`${l.i}`)} multiline rows={6} InputProps={{className: classes.input}} variant="outlined" size="small" fullWidth/>
                                        }

                                        {layout.state[l.i].type == 'linear-scale' &&
                                            // <LinearOptions s={layout.state[l.i]} idx={l.i} />
                                            <RadioGroup row aria-label="gender" value={state[l.i]} onChange={e=>{handleChange(e, l.i)}}>
                                                {linearOptions(layout.state[l.i]).map((o, idx) =>(
                                                    <FormControlLabel key={idx} value={`scale-${o}`} control={(<Radio className={classes.radioButton} />)} label={<Typography variant="body2">{o}</Typography>} />
                                                ))
                                                }
                                            </RadioGroup>
                                        }

                                        {errors[l.i] &&
                                            <div style={{padding: "0.5em 0", display: "flex", alignItems: "center"}}>
                                                <ErrorOutlineIcon style={{marginRight: "0.3em", color: "#e80000"}}/>
                                                <Typography style={{color: "#e80000"}} variant="subtitle2">This is a required question.</Typography>
                                            </div>
                                        }
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div className={classes.container}>
                                <div className={classes.containers}>
                                    {
                                        status.isLoading ? 
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <CircularProgress color="primary" />
                                                <Typography style={{margin: "0 1em"}}>Submitting...</Typography>
                                            </div>
                                        :
                                        <div style={{padding: "0.5em 0", display: "flex", alignItems: "center"}}>
                                            <Button onClick={checkRequired} color="primary" variant="contained">Submit</Button>
                                            {errors && Object.keys(errors).length > 0 &&
                                                <Typography style={{marginLeft: "1em", color: "#e80000"}} variant="subtitle2">Unable to sumbit. Kindly check your answers above.</Typography>
                                            }

                                            {status.isError &&
                                                <Typography style={{marginLeft: "1em", color: "#e80000"}} variant="subtitle2">reCaptcha token was not verified. Please try again.</Typography>
                                            }

                                            {status.haveErrors && !status.isError &&
                                                <Typography style={{marginLeft: "1em", color: "#e80000"}} variant="subtitle2">Something went wrong. Please try again.</Typography>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* <pre style={{color: 'white'}}>{JSON.stringify(layout.state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Questionnaire);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { crud } from '../../services/crud';
import CircularProgress from '@material-ui/core/CircularProgress';
import path from 'path';
import $storage from '../../services/storage';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const UploadContent = (props) => {
    const history = useHistory();
    const {idx, applicant, data, details, model = "requirement"} = props;
    const r = crud(model + "s");
    const [isLoading, setIsLoading] = React.useState(false);
    const [state, setState] = React.useState(data || {
        [`${model}Id`]: idx,
        applicant: applicant._id,
        status: "pending",
        status_:{label: "Pending", value: "pending"}
    });

    const onUploadSuccess = async(f) => {
        if(f.length > 0) {
            console.log(f[0]);
            setTimeout(async ()=>{
                setState({
                    ...state,
                    file: f,
                    file_: f[0]
                });
                const st = {...state, file: f, file_: f[0]}
                const res = await r.save({...st});
                history.go(0);
            }, 50);
        }
      };
    
      let base = "uploads";
    
      const upload = (files) => {
        let p = [];
        Array.prototype.forEach.call(files || [], (file) => {
          p.push(
            $storage.upload(file, {
              path: path.join(base || '', file.name),
              // path: 'tmp/' + file.name,
              onUploadProgress: (p) => {
                console.log(file.name);
                console.log(p);
              },
            })
          );
        });
    
        Promise.all(p).then((res) => {
          let files = res.map((f) => {
            if (!f || !f.data) {
              return '';
            }
            return f.data.file;
          });
    
          onUploadSuccess(files);
        });
      };
    
      const onFileChange = (evt) => {
          setIsLoading(true);
        upload(evt.target.files);
      };

    return (
        <div style={{ margin: '1.5em 0', display: "flex", alignItems: "center", flexDirection: "column" }}>
           {state.file_ &&  <Typography variant="subtitle2">{path.basename(state.file_.name)}</Typography>}
            <input
                style={{ display: 'none' }}
                id={`${idx}-upload-file`}
                type="file"
                onChange={onFileChange}
            />
            {isLoading ?
                <div style={{padding: "0.5em 0"}}><CircularProgress size={30}/></div>
                :
                <label htmlFor={`${idx}-upload-file`}>
                    <Button startIcon={<CloudUploadIcon style={{color: "#fff"}}/>} disabled={state.status == "confirmed"} style={{ margin: '0.5em 0' }} variant="contained" color="primary" component="span">
                    Upload
                    </Button>
                </label>
            }
            {state.file && 
                <div>
                    {
                        state.status == "confirmed" ?
                            <Chip style={{margin: "2em 0"}} icon={<CheckCircleIcon />} color="primary" label={state.status_.label}/>
                        :
                            <Chip style={{margin: "2em 0"}} label={state.status_.label}/>
                    }
                </div>
            }
        </div>
    );
}

export default UploadContent;
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, ListItemText, Avatar } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { crudAgent } from '../../../../services/agentPortalService';
import { AuthContext } from '../../../../auth/AuthContext';
import config from '../../../../config/config';


const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0
  },
  button: {
    color: "#FFF",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontFamily: "Poppins"
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  nested: {
    color: '#FFF',
    width: '100%',
    paddingLeft: "1em", 
    fontSize: "0.875em",
    fontWeight: "500",
    fontFamily: "Poppins"
  },  
  mobilePanel: {
    backgroundColor: "rgba(0,0,0,0.99)",
    padding: "16px"
  }
}));

const CustomRouterLink = forwardRef((props, ref) => {

  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');

    const isUrl = !!pattern.test(props.to);

    
  return (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      {
        isUrl ? 

        <a href={props.to} {...props}></a>
        :
        <RouterLink {...props} />
      }
    </div>
  )
});

const ProfileDropDown = (props) => {
  const art = crudAgent('auto');
  const { user } = React.useContext(AuthContext);
  const [me, setMe] = React.useState(user.user.info);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [mabuhay, setMabuhay] = React.useState(false);

  const getMabuhay = async () => {
    let res = await art.findOne(me.email);
    if(res) {
        if(res.data) {
          setMabuhay(res.data);
        }
    }
  }
  
  React.useEffect(()=>{
    getMabuhay();
  },[]);

  const handleClick = () => {
    setOpen(!open);
  };

  return(
    <React.Fragment>
      <ListItem
        className={classes.item}
        disableGutters
        // key={page.title}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          onClick={handleClick}
        >
          <Avatar
            alt="Person"
            className={classes.avatar}
            component="div"
            src={`https://avatars.dicebear.com/v2/initials/${props.name.charAt(0)}.svg`}
          />
          <ListItemText style={{textAlign: "left"}} disableTypography className={classes.nested} primary={props.name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ListItem>
        <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {
            props.isAgent &&
            <ListItem button onClick={()=>{window.location.href = mabuhay.url}}>
              <ListItemText disableTypography item className={classes.nested} primary="Mabuhay" />
            </ListItem>
          }
          <ListItem button onClick={()=>{props.history.push("/requirements")}}>
            <ListItemText disableTypography item className={classes.nested} primary="Requirements" />
          </ListItem>
          <ListItem button onClick={()=>{props.onLogout()}}>
            <ListItemText disableTypography className={classes.nested} primary="Logout" />
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  )
}

const ProfileNav = withRouter(ProfileDropDown);

const TopbarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.mobilePanel}>
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            {page.label}
          </Button>
        </ListItem>
      ))}
      <ProfileNav isAgent={props.isAgent} name={props.name} onLogout={props.onLogout}/>
    </List>
    </div>
  );
};

TopbarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default TopbarNav;
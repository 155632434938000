import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { AuthContext } from "./auth/AuthContext";
import DefaultLayout from "./layouts/default/";
import AuthenticatedLayout from "./layouts/authenticated/";
import FullLayout from "./layouts/full/";

import Home from './views/Home';
import Team from './views/Team';
import Articles from './views/Articles';
import Article from './views/Article';
import Banner from './views/Banner';
import ApplyNow from './views/ApplyNow';
import Login from './views/Login';
import Videos from './views/Videos';
import Trainings from './views/Trainings';
import ContactUs from './views/ContactUs';
import PersonalDetails from './views/PersonalDetails';
import Questionnaire from './views/Questionnaire';
import Completed from './views/Completed';
import Requirements from './views/Requirements';
import RequirementsDetails from './views/RequirementsDetails';
import Onboarding from './views/Onboarding';
import TermsAndConditions from './views/TermsAndConditions';
import Downloadables from './views/Downloadables';
import Products from './views/Products';
import Webinars from './views/Webinars';
import Webinar from './views/Webinar';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Testimonials from './views/Testimonials';
import Logout from './views/Logout';
// import Sample from './views/Sample';
import NotFound from './views/NotFound';


export default function Routes() {
    const auth = React.useContext(AuthContext);

    const checkAuth= () => {
        return Object.keys(auth.user).length > 0;
    }

    const isAuthenticated = checkAuth();

    const _routes = [
        {
        path: "/home",
        comp: <Home />,
        isAuth: false
        },
        {
            path: "/team",
            comp: <Team />,
            isAuth: false
        },
        {
            path: "/articles/:id",
            comp: <Article />,
            isAuth: false
        },
        {
            path: "/blog",
            comp: <Articles />,
            isAuth: false
        },
        {
            path: "/banners/:id",
            comp: <Banner />,
            isAuth: false
        },
        {
            path: "/apply-now",
            comp: <ApplyNow />,
            isAuth: false
        },
        {
            path: "/login",
            comp: <Login />,
            isAuth: false
        },
        {
            path: "/videos",
            comp: <Videos />,
            isAuth: false
        },
        {
            path: "/trainings",
            comp: <Trainings />,
            isAuth: false
        },
        {
            path: "/products",
            comp: <Products />,
            isAuth: false
        },
        {
            path: "/webinars",
            comp: <Webinars />,
            isAuth: false
        },
        {
            path: "/webinars/:id",
            comp: <Webinar />,
            isAuth: false
        },
        {
            path: "/contact-us",
            comp: <ContactUs />,
            isAuth: false
        },
        {
            path: "/personal-details",
            comp: <PersonalDetails />,
            isAuth: false
        },
        {
            path: "/questionnaire",
            comp: <Questionnaire />,
            isAuth: false
        },
        {
            path: "/completed",
            comp: <Completed />,
            isAuth: false
        },
        {
            path: "/requirements",
            comp: <Requirements />,
            isAuth: false
        },
        {
            path: "/requirements/:id",
            comp: <RequirementsDetails />,
            isAuth: false
        },
        {
            path: "/termsandconditions",
            comp: <TermsAndConditions />,
            isAuth: false
        },
        {
            path: "/downloadables",
            comp: <Downloadables />,
            isAuth: false
        },
        {
            path: "/testimonials",
            comp: <Testimonials />,
            isAuth: false
        },
        {
            path: "/forgotpassword",
            comp: <ForgotPassword />,
            isAuth: false
        },
        {
            path: "/resetpassword",
            comp: <ResetPassword />,
            isAuth: false
        },
        // {
        //     path: "/sample",
        //     comp: <Sample />,
        //     isAuth: false
        // },
        // {
        //     path: "/onboarding",
        //     comp: <Onboarding />,
        //     isAuth: false
        // },
        {
            path: "/404",
            comp: <NotFound />,
            isAuth: false
        }
    ];

    const paths = _routes.map((x, idx) => {
        return (isAuthenticated ?
        <Route exact path={x.path} key={idx}>
                <AuthenticatedLayout>
                {x.comp}
                </AuthenticatedLayout> 
        </Route> :
        !x.isAuth ? 
            <Route exact path={x.path} key={idx}>
            <DefaultLayout>
                {x.comp}
            </DefaultLayout> 
        </Route>
        :
        <Redirect exact from={x.path} to="/login" key={idx} />
        )
    });

    return (
        <Switch>
            <Redirect exact from="/" to="home" key="home" />
            {paths}
            {
                isAuthenticated ? 
                <Route exact path="/logout" key="logout">
                    <Logout />
                </Route>
                :
                <Redirect exact from="/logout" to="/login" key="logout" />
            }
            <Route exact path="/onboarding" key="onboarding">
                <FullLayout>
                    <Onboarding />
                </FullLayout>
            </Route>
            <Redirect to="/404" />
        </Switch>
    )
}
    
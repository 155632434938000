import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { cyan, orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { crud } from '../services/crud';
import { AuthContext } from '../auth/AuthContext';
import $http from '../services/http';
import config from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { WidthProvider, Responsive } from 'react-grid-layout';
import {format} from 'date-fns';
import DefaultContent from '../components/requirements/DefaultContent';
import qs from 'qs';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        minHeight: "calc(100vh - 80px)",
        padding: "4.5em 13%"
    }
}));

const Onboarding = (props) => {
    const { location } = props;
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [layout, setLayout] = React.useState({layout: []});
    
    const [applicant, setApplicant] = React.useState({});
    const [requirements, setRequirements] = React.useState([]);
    const [isNotAuthorized, setIsNotAuthorized] = React.useState(false);
    const a = crud('applicants');

    const q = qs.parse(location.search, { ignoreQueryPrefix: true });
    const id = q.id;

    // const token = q.token;

    const isMe = q.me || false;
    let errorTimeout;

    // const requestHandler = async (url, method, data) =>{
    //     let conf = {
    //         method: method,
    //         headers: {'Authorization': `Bearer ${token}`}
    //     };

    //     if(method == 'POST' || method == 'PUT') {
    //         conf.data = {...data};
    //     } else {
    //         conf.params = {...data}
    //     }

    //     const res = await $http(`${config.app.server.url}${url}`, conf);

    //     return res;
    // }

    const getApplicant = async () => {

        const res = await a.findOne(q.id);
        // const res = await requestHandler(`/applicants/${id}`, 'GET', {});
        if(res){
            if(res.data) {
                setApplicant(res.data);
                if(res.data.status !== "applicant") {
                    getRequirements(res.data._id);
                }
            }
        }        
    }

    const retrieve = async () => {
        const s = crud('settings');
        const res = await s.find({
          key: 'onboarding',
        });
        // const res = await $http.get('',
        // {
        //     params: {
        //        " oid:applicant": id
        //     },
        //     headers: {'Authorization': `Bearer ${token}`},
        // });

        // const res = await requestHandler('/settings', 'GET', {key: 'onboarding'});

        // const res = await $http.get(`${config.app.server.url}/settings`,
        // {
        //     params: {
        //        key: 'onboarding'
        //     },
        //     headers: {'Authorization': `Bearer ${token}`},
        // });
    
        if (res) {
          if (res.data.length > 0) {
            setLayout({...res.data[0].json});
          }
        }
    };

    const getRequirements = async (id) => {
        const r = crud("onboardings");
        const res = await r.find({"oid:applicant": id || applicant._id});
        // const res = await requestHandler('/onboardings', 'GET', {"oid:applicant": id || applicant._id});
        if(res) {
            if(res.data) {
                retrieve();
                if(res.data.length > 0) {
                    setRequirements(res.data);
                }
            }
        }
    }

    const ex = {
        cols: { lg: 3, md: 3, sm: 3, xs: 2, xxs: 1 },
        rowHeight: 100,
        isBounded: true,
        // breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        isDraggable: false,
        isResizable: false
    };

    const getRequirementIdx = (id) => {
        const i = requirements.findIndex(x => x.onboardingId === id);
        return i;
    }

    const setToken = async (token) => {
        console.log(token);
        if(token) {
            $http.defaults.headers.common["Authorization"] = "Bearer " + token;
            await $http
            .get(config.app.server.url + "/users/me")
            .then(() => {
                getApplicant();
            })
            .catch(err => {
                delete $http.defaults.headers.common['Authorization'];
                setIsNotAuthorized(true);
            });
        }
    }

    const attachPostMessage = () => {
        errorTimeout = setTimeout(()=>{
            setIsNotAuthorized(true);
        }, 5000);
        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.addEventListener("message", displayMessage, false);
        }
        else {
            window.attachEvent("onmessage", displayMessage);
        }
    }

    const displayMessage = (evt) => {
        // if (evt.origin !== "http://localhost:3000") {
        //     message = "You are not worthy";
        // }
        // else {
            // setToken(evt.data);
        // }
        clearTimeout(errorTimeout);
        if(evt.data) {
            setToken(evt.data);
        } else {
            setIsNotAuthorized(true);
        }
    }

    React.useEffect(()=>{
        attachPostMessage();
        return () => {
            clearTimeout(errorTimeout);
        }
    },[]);

    return (
        <div className={classes.root} style={{padding: "1em"}}>
            <div className={classes.content}>
               {
                   isNotAuthorized ? 
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Typography style={{padding: "0 1em"}} variant="h5">Not Authorized</Typography>
                    </div>
                   :
                   <Fragment>
                       <div style={{display: "flex", flexDirection: "row"}}>
                            <Typography style={{padding: "0 1em"}} variant="h5">Onboarding</Typography>
                        </div>
                        <div>
                            <ResponsiveReactGridLayout
                                    className="layout"
                                    {...ex}
                                    layouts={layout.layout}
                                >
                                    {layout.layout.map((l) => (
                                    <div data-grid={l} key={l.i} style={{ overflowY: "auto", backgroundColor: "teal" }}>
                                        <Paper className={requirements[getRequirementIdx(l.i)] ? `grid-layout-item requirements-${requirements[getRequirementIdx(l.i)].status}` : "grid-layout-item noupdate"} elevation={3}>
                                            <div className="grid-layout-form">
                                            {l.i === 'n1' ? (
                                                <React.Fragment>
                                                    {applicant &&
                                                        <React.Fragment>
                                                            <div>
                                                                <Typography
                                                                    variant="h5"
                                                                    style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                >
                                                                    {`${applicant.firstname} ${applicant.lastname}`}
                                                                    {/* <pre>{JSON.stringify(me, null, 4)}</pre> */}
                                                                </Typography>
                                                            </div>
                                                            {applicant.birthday &&
                                                                <div>
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                    >
                                                                        {`${format(new Date(applicant.birthday), 'MMMM dd, yyyy')}`}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            <div>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                >
                                                                    {`${applicant.email}`}
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                >
                                                                    {`${applicant.contact}`}
                                                                </Typography>
                                                            </div>
                                                            {
                                                                applicant.alternateContact &&
                                                                <div>
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                    >
                                                                        {`${applicant.alternateContact}`}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            <div>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                                >
                                                                    {`${applicant.address}`}
                                                                </Typography>
                                                            </div>
                                                        </React.Fragment>

                                                    }
                                                </React.Fragment>
                                                ) : layout.state[l.i] ? (
                                                <DefaultContent
                                                    index={l.i}
                                                    state={layout.state[l.i]}
                                                    applicant={applicant}
                                                    data={requirements[getRequirementIdx(l.i)]}
                                                    model="onboarding"
                                                    me={isMe}
                                                />
                                                ) : null}
                                            </div>
                                        </Paper>
                                    </div>
                                    ))}
                                </ResponsiveReactGridLayout>
                        </div>
                   </Fragment>
               }
            </div>
        </div>
    )
}

export default withRouter(Onboarding);
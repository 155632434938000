import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { orange, cyan } from '@material-ui/core/colors';
import { crud } from '../services/crud';
import { withRouter } from 'react-router-dom';
import config from "../config/config";
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    img: {
        height: "calc(100vh - 200px)",
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    label: {

    },
    slideItem: {
    },
    text: {
        textAlign: "center",
        fontWeight: "900",
        color: "#ffffff",
        maxWidth: "80%"
    },
    carousel: {
        position: "relative"
    },
    stepperContainer: {
        position: "absolute",
        bottom: "1em",
        width: "100%",
        display: "flex",
        justifyContent: 'center'
    },
    tags:{
        display: "flex",
        padding: "0.2em 0",
        alignItems: "center"
    },
    chip: {
        margin: "0 0.2em",
        cursor: "pointer"
    }
}));

const Banner = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [article, setArticle] = React.useState({});
    const [slides, setSlide] = React.useState([]);
    const art = crud('banners');

    const getArticle = async () => {
        let res = await art.findOne(props.match.params.id);
        if(res){
            if(res.data) {
                let s = [];
                for(const p of res.data.photos) {
                    s.push({
                        label: "",
                        imgPath: `${config.app.server.url}/storage/${p._id}`
                    });
                }
                setSlide(s);
                setArticle(res.data);
            }
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = slides.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    React.useEffect(()=>{
        getArticle();
    },[]);

    return (
        <div>
            <div className={classes.carousel}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {slides.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                        <div className={classes.slideItem}>
                            <div className={classes.img} style={{background: `url(${step.imgPath}) no-repeat center center / cover`}}>
                                <Typography className={classes.text} variant="h3" >{step.label}</Typography>
                            </div>
                        </div>
                        
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
            {slides.length > 1 && <div className={classes.stepperContainer}>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        style={{background: "none"}}
                        className={classes.stepper}
                    />
                </div>}
            </div>
            <div className="content-main-padding">
                {article._id && 
                    <div className="article-main-padding">
                        <Typography variant="h4" style={{fontWeight: 700}}>{article.title}</Typography>
                        <Typography variant="body2">{format(new Date(article.createdAt), "MMMM dd, yyyy")}</Typography>
                        <Typography variant="body2">By: {article.author}</Typography>
                        <Typography style={{padding: "1.5em 0"}}>
                            <span dangerouslySetInnerHTML={{__html: article.body.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></span>
                        </Typography>
                    </div>
                }

                {article.tags &&
                    <div className={classes.tags}>
                        <Typography style={{marginRight: "0.5em"}} variant="subtitle2">Tags: </Typography>
                        {(article.tags.split(",").map((t)=>(
                            <div>
                                { (t.trim().length !== 0 && t.trim() !== "") &&
                                    <Chip
                                        clickable
                                        label={t.trim()}
                                        className={classes.chip}
                                        size="small"
                                    />
                                }
                            </div>
                        ))
                        )}
                    </div>
                }
            </div>
             {/* <pre>{JSON.stringify(article, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Banner);
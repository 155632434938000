import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {format} from 'date-fns';

import LinkContent from './LinkContent';
import TextContent from './TextContent';
import FileContent from './FileContent';
import UploadContent from './UploadContent';
import LinkConfirm from './LinkConfirm';
import TextConfirm from './TextConfirm';
import FileConfirm from './FileConfirm';
import UploadConfirm from './UploadConfirm';

const DefaultContent = (props) => {
    const { index, state, applicant, data, me, model = "requirement" } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    const p = {
        idx: index,
        applicant: applicant,
        data: data
    }

    const meComponent = (type) => {
        switch(type) {
            case "file" : return <FileContent {...p} model={model} details={state}/>;
            case "file-upload" : return  <UploadContent {...p} model={model} />;
            case "link" : return <LinkContent {...p} link={state.link} model={model} />;
            case "text" : return <TextContent {...p} model={model} />
        }
    }

    const applicantComponent = (type) => {
        switch(type) {
            case "file" : case state.file : return <FileConfirm {...p} model={model} details={state} setIsLoading={setIsLoading} />;
            case "file-upload" : return <UploadConfirm {...p} model={model} setIsLoading={setIsLoading}/>;
            case "link" : return <LinkConfirm {...p} model={model} link={state.link} setIsLoading={setIsLoading}/>;
            case "text" : return  <TextConfirm {...p} model={model} setIsLoading={setIsLoading}/>
        }
    }
  
    return (
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
          <React.Fragment>
            <Typography variant={'h5'} style={{ padding: '0.25em 0' }}>
              {state.title}
            </Typography>
            <Typography style={{ padding: '0.25em 0' }}>
              {state.description}
            </Typography>
            <Typography style={{ padding: '0.25em 0' }}>
              {state.details}
            </Typography>
            {me ? 
                    meComponent(state.type) 
                : 
            isLoading ?
                    <div style={{width: "100%", textAlign: "center", padding: "3em 0"}}>
                        <CircularProgress />
                    </div>
                :
                    applicantComponent(state.type)}
            
          </React.Fragment>

          {
            data && 
            data.updatedAt &&
            <div style={{textAlign: "center"}}>
              <Typography style={{ padding: '0.25em 0' }}>
                {data.status == "confirmed" ? "Confirmed on" : "Submitted on"}
              </Typography>
              <Typography>
                {format(new Date(data.updatedAt), "MMM. dd, yyyy hh:mm a")}
              </Typography>
            </div>
          }

          {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
      </div>
    );
};

export default DefaultContent;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { cyan, orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { crud } from '../services/crud';
import { AuthContext } from '../auth/AuthContext';
import $http from '../services/http';
import config from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { WidthProvider, Responsive } from 'react-grid-layout';
import {format} from 'date-fns';
import DefaultContent from '../components/requirements/DefaultContent';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        minHeight: "calc(100vh - 80px)",
        padding: "4.5em 13%"
    }
}));

const RequirementsDetails = (props) => {
    const { history } = props;
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [layout, setLayout] = React.useState({layout: [{
        i: 'n1',
        x: 0,
        y: 0,
        w: 1,
        h: 5,
        static: true,
      }]});
    
    const [applicant, setApplicant] = React.useState(user.user.info);
    const [requirements, setRequirements] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const a = crud('applicants');

    const id = props.match.params.id;

    const getApplicant = async () => {
        if(id == "me") {
            if(applicant.status !== "applicant") {
                getRequirements();
            }
            getAnswers();
            return;
        }

        const res = await a.findOne(id);
        if(res){
            if(res.data) {
                setApplicant(res.data);
                if(res.data.status !== "applicant") {
                    getRequirements(res.data._id);
                }
            }
        }

        getAnswers();
        
    }

    // const addNewGrid = () => {
    //     const _layout = layout || [];
    //     const l = {
    //         i: uuidv4(),
    //         x: layout.length % 3,
    //         // x: 4,
    //         y: Infinity,
    //         w: 1,
    //         h: 5,
    //     };
    //     _layout.push(l);
    //     setLayout([..._layout]);
    //     setState({ ...state, [`${l.i}`]: {} });
    // };

    const retrieve = async () => {
        const s = crud('settings');
        const res = await s.find({
          key: 'requirements',
        });
    
        if (res) {
          if (res.data.length > 0) {

            const _layout = res.data[0].json.layout;
            const l = {
                i: "agent-code",
                x: _layout.length % 3,
                // x: 4,
                y: Infinity,
                w: 1,
                h: 5,
            };
            _layout.push(l);

            const agentCode = 
            {
                title: "FWD Agent Code",
                description: "A FWD Agent Code will be assigned to you upon completion of the requirements and passing the IC Exams.",
                details: "Please input your Agent Code below.",
                type: "text",
                type_: {
                    label: "Text",
                    value: "text"
                }
            };

            const _state = res.data[0].json.state;
            _state['agent-code'] = agentCode;
            setLayout({layout: [..._layout], state: {..._state}});
          }
        }
    };

    const getRequirements = async (id) => {
        const r = crud("requirements");
        const res = await r.find({"oid:applicant": id || applicant._id});
        if(res) {
            if(res.data) {
                retrieve();
                if(res.data.length > 0) {
                    setRequirements(res.data);
                }
            }
        }
    }

    const getAnswers = async () => {
        const r = crud("answers/member");
        let _id = id;
        if(id == "me") {
            _id = applicant._id;
        }
        const res = await r.findOne(_id);
        if(res) {
            if(res.data) {
                if(res.data.length > 0) {
                    setAnswers(res.data);
                }
            }
        }
    }

    const onAccept = async (id) => {
        const res = await $http.post(config.app.server.url + `/applicants/accept/${id}`);
        setIsLoading(false);
        getApplicant();
    }

    const ex = {
        cols: { lg: 3, md: 3, sm: 3, xs: 2, xxs: 1 },
        rowHeight: 100,
        isBounded: true,
        // breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        isDraggable: false,
        isResizable: false
    };

    const getRequirementIdx = (id) => {
        const i = requirements.findIndex(x => x.requirementId === id);
        return i;
    }

    React.useEffect(()=>{
        getApplicant();
    },[]);

    return (
        <div className={classes.root} style={{padding: "1em"}}>
            <div className={classes.content}>
               <div style={{display: "flex", flexDirection: "row"}}>
                    <Typography style={{padding: "0 1em"}} variant="h5">Requirements</Typography>
                    <a href="/downloadables" onClick={(e)=>{e.preventDefault(); history.push("/downloadables")}}><Typography style={{padding: "0 1em", color: "#fff"}} variant="h5">Downloadables</Typography></a>
               </div>
               <div>
                <ResponsiveReactGridLayout
                        className="layout"
                        {...ex}
                        layouts={layout.layout}
                    >
                        {layout.layout.map((l) => (
                        <div data-grid={l} key={l.i} style={{ overflowY: "auto", backgroundColor: "teal" }}>
                            <Paper className={requirements[getRequirementIdx(l.i)] ? `grid-layout-item requirements-${requirements[getRequirementIdx(l.i)].status}` : "grid-layout-item noupdate"} elevation={3}>
                                <div className="grid-layout-form">
                                    {l.i === 'n1' ? (
                                    <React.Fragment>
                                        {applicant &&
                                            <React.Fragment>
                                                <div>
                                                    <Typography
                                                        variant="h5"
                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                    >
                                                        {`${applicant.firstname} ${applicant.lastname}`}
                                                        {/* <pre>{JSON.stringify(me, null, 4)}</pre> */}
                                                    </Typography>
                                                </div>
                                                {applicant.birthday &&
                                                    <div>
                                                        <Typography
                                                            variant="body2"
                                                            style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                        >
                                                            {`${format(new Date(applicant.birthday), 'MMMM dd, yyyy')}`}
                                                        </Typography>
                                                    </div>
                                                }
                                                <div>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                    >
                                                        {`${applicant.email}`}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                    >
                                                        {`${applicant.contact}`}
                                                    </Typography>
                                                </div>
                                                {
                                                    applicant.alternateContact &&
                                                    <div>
                                                        <Typography
                                                            variant="body2"
                                                            style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                        >
                                                            {`${applicant.alternateContact}`}
                                                        </Typography>
                                                    </div>
                                                }
                                                <div>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ padding: '0.25em 0', textAlign: 'center' }}
                                                    >
                                                        {`${applicant.address}`}
                                                    </Typography>
                                                </div>
                                                <div style={{padding: "1em 0em"}}>
                                                    {(
                                                        answers.map((a, idx) => (
                                                            <div style={{padding: "0.5em 0.25em"}}>
                                                                <Typography style={{fontWeight: 500}}>{a.q}</Typography>
                                                                <Typography>{a.a}</Typography>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                                {applicant.status === "applicant" &&
                                                    <div style={{display: "flex", justifyContent: "center", margin: "1em"}}>
                                                    {
                                                        isLoading ? 
                                                            <CircularProgress size={25}/>
                                                        :
                                                        <React.Fragment>
                                                            <Button
                                                                style={{margin: "0 0.2em"}}
                                                                color="primary"
                                                                onClick={()=>{setIsLoading(true); onAccept(applicant._id)}}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                style={{margin: "0 0.2em"}}
                                                                color="secondary"
                                                            >
                                                                Decline
                                                            </Button>
                                                        </React.Fragment>
                                                    }
    
                                                    </div>
                                                }
                                            </React.Fragment>

                                        }
                                    </React.Fragment>
                                    ) : layout.state[l.i] ? (
                                    <DefaultContent
                                        index={l.i}
                                        state={layout.state[l.i]}
                                        applicant={applicant}
                                        data={requirements[getRequirementIdx(l.i)]}
                                        me={id === "me" ? true : false}
                                    />
                                    ) : null}
                                </div>
                            </Paper>
                        </div>
                        ))}
                    </ResponsiveReactGridLayout>
               </div>
            </div>
        </div>
    )
}

export default withRouter(RequirementsDetails);
import React, {useState} from "react";
import cache from '../services/cache.js';

const ApplicantContext = React.createContext();

const ApplicantProvider = (props) => {
    const c = cache.get('applicant', '');
    const [applicant, setApplicant] = useState(c);

     const _setApplicant = (value) => {
        cache.put('applicant', value, {
            persist: true
        });
        setApplicant(value);
     }

    return (
        <ApplicantContext.Provider value={{applicant: applicant, setApplicant: _setApplicant}}>
           {props.children}
        </ApplicantContext.Provider>
    )
}

export {ApplicantContext, ApplicantProvider};
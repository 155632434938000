import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CallIcon from '@material-ui/icons/Call';
import ScheduleIcon from '@material-ui/icons/Schedule';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contactForm: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `solid 2px #2a7fff`,
        flexDirection: 'column',
        padding: "4em 2em",
        width: "550px",
        margin: "auto"
    },
    learnInputs: {
        maxWidth: "450px",
        marginTop: "2em"
    },
    learnInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    contactItem: {
        display: "flex",
        padding: "0.5em 0"
    }
}));

const ContactUs = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div>
            
            <div className="content-main-padding">
                <Grid container>
                    <Grid item md={6} style={{padding: "3em 3em"}}>
                        <Typography variant="h5">Contact Information</Typography>
                        <Typography variant="body2" style={{marginTop: "1em"}}>Drop us a line or give us a ring. We love to hear about your experience. We are happy to answer any questions.</Typography>
                        <Typography variant="h6" style={{marginTop: "2em", marginBottom: "0.25em", fontWeight: 700}}>
                            1670 Mabuhay Planners and Associates
                        </Typography>
                        <div className={classes.contactItem}>
                            <PinDropIcon variant="small" style={{marginRight: "0.28em"}}/>
                            <Typography variant="body1">
                                16th floor, Mabuhay Tower, Jose Maria del Mar Cor. Abad St., Cebu IT Park, Cebu City
                            </Typography>
                        </div>
                        <div className={classes.contactItem}>
                            <CallIcon variant="small" style={{marginRight: "0.28em"}}/>
                            <Typography variant="body1">
                                (032) 254 3549
                            </Typography>
                        </div>
                        <div className={classes.contactItem}>
                            <ScheduleIcon variant="small" style={{marginRight: "0.28em"}}/>
                            <Typography variant="body1">
                                Mon-Sat, 9am to 5pm (Except Holidays)
                            </Typography>
                        </div>
                    </Grid> 
                    <Grid item md={6}>
                        <Grid container>
                            <Grid item sm={12} md={12} style={{padding: "3em 3em"}}>
                                <Paper className={classes.contactForm} elevation={2}>
                                <Typography variant="h4">Get in touch with us</Typography>
                                <Typography variant="body2">If you have a question, don't hesitate to send us a message.</Typography>
                                <div className={classes.learnInputs}>
                                        <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Email Address" fullWidth/>
                                        <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Name" fullWidth/>
                                        <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" placeholder="Subject" fullWidth/>
                                        <TextField InputProps={{ className: classes.learnInput }} variant="outlined" size="small" rows={6} placeholder="Message" multiline fullWidth/>
                                </div>
                                <div style={{width: "450px", padding: "0.75em 0"}}>
                                        <Button style={{float: "right"}} color="primary" variant="contained">Submit</Button>
                                </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default ContactUs;
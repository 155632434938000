import React from 'react';
import merge from 'merge';
import { mutateState } from '../services/utility';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const Store = React.createContext();

const initialState = {
  open: false,
  title: '',
  message: '',
  actions: [],
  key: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

/* params: { path:value } */
function setState(params) {
  return {
    type: 'SET_STATE',
    ...params,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      let params = { ...action };
      delete params.type;
      state = mutateState(state, params);
      return { ...state };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const classes = useStyles();
  const config = merge.recursive(initialState, props.config || {});
  const [state, dispatch] = React.useReducer(reducer, config);

  const showModal = (params) => {
    dispatch(setState({ ...params, open: true }));
  };

  const closeModal = () => {
    dispatch(setState({ open: false }));
  };

  const setActions = (actions) => {
    dispatch(
      setState({
        actions: actions,
      })
    );
  };

  const setText = (text) => {
    dispatch(
      setState({
        title: text.title,
        message: text.message,
      })
    );
  };

  const setKey = (key) => {
    dispatch(
      setState({
        key: key,
      })
    );
  };

  const onAction = (action) => {
    if (action.action) {
      if (action.action() === false) {
        return;
      }
    }
    closeModal();
  };

  const value = {
    state,
    dispatch,
    setState,
    showModal,
    closeModal,
    setText,
    setActions,
    setKey,
  };

  const actions = state.actions.map((action, idx) => {
    return (
      <Button
        key={idx}
        onClick={(evt) => {
          onAction(action);
        }}
        color="primary"
      >
        {action.title}
      </Button>
    );
  });

  const ModalComponent = state.component;
  const componentProps = state.componentProps || {};
  const key = state.key;

  return (
    <Store.Provider value={value}>
      {props.children}
      <Dialog
        fullScreen={state.fullScreen}
        open={state.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        {state.title && <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>}
        {ModalComponent && <ModalComponent {...componentProps} key={key} />}
        {!ModalComponent && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {state.message}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </Store.Provider>
  );
}

const useModal = () => {
  const {
    showModal,
    closeModal,
    setActions,
    setText,
    setKey,
  } = React.useContext(Store);
  return { showModal, closeModal, setActions, setText, setKey };
};

export { useModal };

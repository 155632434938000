import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import { format } from 'date-fns';
import config from '../config/config';
import { withRouter } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const Products = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [products, setProducts] = React.useState([]);
    const art = crud('products');

    const getProducts = async () => {
        const prod = crud('products');
        let res = await prod.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    setProducts(res.data);
                }
            }
        }
    }

    const onLinkClick = (url) => {
        history.push(url);
    }

    const openProduct = (url) => {
        window.open(url, '_blank').focus();
    }

    React.useEffect(()=>{
        getProducts();
    },[]);

    return (
        <div className="content-main-padding">
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
                <Grid container>
                    <Grid item sm={12} className={classes.learn}>
                        <Typography style={{padding: "2.5em 0 0.75em 0"}} variant="h5">LEARN MORE ABOUT OUR DIFFERENT FINANCIAL PRODUCTS</Typography>
                        <Typography variant="body2">Click on a financial product to learn more, you will be redirected to the official FWD web page.</Typography>
                        <Typography variant="body2">For more information, send us an email and one of our financial wealth planners will get in touch with you at your most convenient time.</Typography>
                    </Grid>
                </Grid>
                <div style={{flexGrow: 1, marginTop: "2em"}}>
                    <Grid container>
                        {(products.map((p, idx)=>(
                            <Grid key={idx} item xs={12} sm={12} md={4} style={{padding: "4px"}} onClick={()=>{ openProduct(p.hyperlink) }}>
                                <Box boxShadow={2} className="product-item">
                                    <img className="product-item-img" src={`${config.app.server.url}/storage/${p.photo[0]}`} />
                                    <Typography variant="h6" style={{paddingTop: "1em"}}>{p.title}</Typography>
                                    <Typography style={{paddingTop: "1em"}}>
                                        {p.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))

                        )}
                    </Grid>
                </div>
        </div>
    )
}

export default withRouter(Products);
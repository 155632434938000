import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import StateHelper from '../services/stateHelper';
import { isRequired } from '../services/validators';
import {AuthContext} from '../auth/AuthContext';
import config from '../config/config';
import $http from '../services/http';
import CircularProgress from '@material-ui/core/CircularProgress'; 

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    content: {
        width: "100%",
        padding: "5em 5em",
        minHeight: "calc(100vh - 108px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loginForm: {
        background: "rgba(10,10,10,0.6)",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        padding: "4em 3em"
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "100%",
        padding: "1em 3em"
    }
}));

const ResetPassword = (props) => {
    const { history } = props;
    const params = new URLSearchParams(props.location.search);
    const code = params.get('code');
    // if (!code) {
    //     history.push('/login');
    // }
    const classes = useStyles();
    const {setUser} = React.useContext(AuthContext);
    const [isDone, setIsDone] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [state, setState] = React.useState({code: code});

    fs.useState(state, setState);
    fs.useValidator({
        password: isRequired,
        repassword: isRequired
    });

      const onResetPassword = async () => {
        setIsLoading(true);
        const res = await $http({
            method: 'POST',
            url: config.app.server.url + `/auth/reset-password`,
            data: {
              ...state
            },
        });

        if(res) {
            if(res.data) {
                setIsDone(true);
            }
        }

        setTimeout(()=>{
            setIsLoading(false);
        }, 1500);
      }

      const onLogin = async () => {
        await fs.validateStateWithWait();
        await confirmPassword();
        if(!fs.hasErrors()) {
            //localLogin();
            onResetPassword();
        }
    }

    const confirmPassword = async () => {
        if(state.password.trim() !== state.passwordConfirmation.trim()) {
            fs.setState({
                _errors: {
                    ...state._errors,
                    passwordConfirmation: {
                        "invalid": "true",
                        "error": "isRequired",
                        "message": "The new password do not match."
                    }
                }
            });
        }
    }

    return (
        <div>
            <div className={classes.content}>
                <Grid container>
                    <Grid sm={12} md={8} item className={classes.logoContainer}>
                        <img className={classes.logo} src="./assets/images/logo.png"/>
                    </Grid>
                    <Grid sm={12} md={4} item>
                        <Paper className={classes.loginForm}>
                        {
                              isDone ? 
                              <div>
                                  <Typography style={{color: "#fff"}} variant="h6">Password Changed.</Typography>
                                  <Typography style={{color: "#fff", paddingTop: "1em"}}>You may now login using your new password.</Typography>
                                  <Button style={{marginTop: "2em"}} href="/#/login" color="primary" variant="contained" fullWidth>Login</Button>
                              </div>
                              :
                              <React.Fragment>
                                <Typography style={{color: "#fff"}}>Enter New Password</Typography>
                                <TextField type="password" {...fs.model("password")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small"/>
                                <Typography style={{color: "#fff"}}>Re-enter New Password</Typography>
                                <TextField type="password"  {...fs.model("passwordConfirmation")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" helperText={(state._errors && state._errors["passwordConfirmation"]) ? state._errors["passwordConfirmation"].message : ""}/>
                                {/* {isFailed && <Typography style={{color: "#f44336"}} variant="subtitle2">Email or password is incorrect.</Typography>} */}
                                
                                <div style={{textAlign: "right", padding: "1em 0"}}>
                                    {
                                        isLoading ?
                                        <CircularProgress size={30}/>
                                        :
                                        <Button onClick={onLogin} color="primary" variant="contained">Submit</Button>
                                    }
                                </div>
                              </React.Fragment>
                        }
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(ResetPassword);
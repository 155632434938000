import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "#fff",
    width: "100%",
    padding: "3em 5em"
  },
  logo: {
    height: "100px",
    margin: "0em",
  },
  copyright:{
    background: "#ef1a1a",
    textAlign: "center",
    color: "#fff",
    padding : "3px 0px",
  }
}));

function Footer(props) {
  const { history } = props;
  const classes = useStyles();

  const linkOnClick = (route) => {
    history.push(`${route}`);
  }

  return (
    <div>
    <div className={classes.footer}>
          <Grid container>
            <Grid item sm={12} md={3}>
              <Typography>
                16th floor, Mabuhay Tower
              </Typography>
              <Typography>
                Jose Maria del Mar Cor. Abad St.
              </Typography>
              <Typography>
                Cebu IT Park, Cebu City
              </Typography>
            </Grid>
            
            <Grid item sm={12} md={3}>
              <Typography>
                Call us 032 254 3549 / 09998816700
              </Typography>
              <Typography>
                Office Hours: Mon-Sat, 9am to 5pm <br/>(Except Holidays)
              </Typography>
            </Grid>
            <Grid item sm={12} md={3}>
              <Typography style={{alignItems: "center"}}>
                Connect with us &nbsp;<br />
                <IconButton target="_blank" href="https://www.facebook.com/1670-Mabuhay-Planners-and-Associates-862077730835998/" size="small" aria-label="delete">
                  <FacebookIcon style={{color: "#fff"}} fontSize="small"/>
                </IconButton>
                <IconButton target="_blank" href="https://www.facebook.com/1670-Mabuhay-Planners-and-Associates-862077730835998/" size="small" aria-label="delete">
                  <InstagramIcon style={{color: "#fff"}} fontSize="small"/>
                </IconButton>
                <br />
                <a onClick={(e)=>{e.preventDefault(); history.push("/termsandconditions")}} href="/termsandconditions"><Typography style={{color: "#fff"}}>Website Terms and Conditions</Typography></a> 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={{textAlign: "center"}}>
              <img className={classes.logo +" footer-logo"} src="./assets/images/circular-logo.png"/> 
            </Grid>
          </Grid>
    </div>
    <div className={classes.copyright}>
        <Grid container className="copyrightInner">
            <Grid item sm={12} md={12} style={{padding: "0.3em 0"}}>
              &copy;2021 1670 Mabuhay. All Rights Reserved
            </Grid>
        </Grid>
    </div>
  </div>
  );
}

export default withRouter(Footer);
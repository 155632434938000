import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    content: {
        width: "100%",
        padding: "5em 5em",
        minHeight: "calc(100vh - 108px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loginForm: {
        background: "#2a7fff",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "4em 3em",
        color: "#fff"
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "100%",
        padding: "1em 3em"
    },
    textBox: {
        padding: "1em 0em" 
    }
}));

const Completed = (props) => {
    const { history } = props;
    const classes = useStyles();

    return (
        <div>
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
            <div className={classes.content}>
                <Grid container>
                    <Grid sm={12} md={8} item className={classes.logoContainer}>
                        <img className={classes.logo} src="./assets/images/logo.png"/>
                    </Grid>
                    <Grid sm={12} md={4} item className={classes.textBox}>
                        <Paper className={classes.loginForm}>
                            <Typography>Thank you for your interest in joining our team.</Typography>
                            <br/>
                            <Typography>We will got in touch with you soon!</Typography>
                            <br/>
                            <Typography>Stay Safe!</Typography>
                            <br/>
                            <Button variant="contained" color="primary" href="/#/">DONE</Button>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default withRouter(Completed);
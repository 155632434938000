import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import StateHelper from '../services/stateHelper';
import { isRequired } from '../services/validators';
import {AuthContext} from '../auth/AuthContext';
import config from '../config/config';
import $http from '../services/http';
import CircularProgress from '@material-ui/core/CircularProgress'; 

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    content: {
        width: "100%",
        padding: "5em 5em",
        minHeight: "calc(100vh - 108px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loginForm: {
        background: "rgba(10,10,10,0.6)",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "4em 3em"
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "80%",
        padding: "1em 3em"
    }
}));

const ForgotPassword = (props) => {
    const classes = useStyles();
    const [isDone, setIsDone] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [state, setState] = React.useState({});
    const [isError, setIsError] = React.useState({show: false, message: ""});

    fs.useState(state, setState);
    fs.useValidator({
        email: isRequired
    });    

      const onForgotPassword = async () => {
        setIsLoading(true);
        let msg = "";
        let haveErrors = false;
        const res = await $http({
            method: 'POST',
            url: config.app.server.url + `/auth/forgot-password`,
            data: {
              ...state,
            },
        })
        .catch(error => {
            // Handle error.
            haveErrors = true;
            console.log('An error occurred:', error.response);
            if(error) {
                if(error.response) {
                    msg = error.response.data.message[0].messages[0].message;
                }
            }
        });
        if(res) {
            if(res.data) {
                setIsDone(true);
            }
        }

        setTimeout(()=>{
            if(haveErrors) {
                if(msg !== '') {
                    setIsError({show: true, message: msg});
                }
                else {
                    setIsError({show: true, message: ""});
                }
            }
            setIsLoading(false);
        }, 1500);
      }

      const onLogin = async () => {
        await fs.validateStateWithWait();
        if(!fs.hasErrors()) {
            //localLogin();
            onForgotPassword();
        }
    }

    return (
        <div>
            <div className={classes.content}>
                <Grid container>
                    <Grid sm={12} md={8} item className={classes.logoContainer}>
                        <img className={classes.logo} src="./assets/images/logo.png"/>
                    </Grid>
                    <Grid sm={12} md={4} item>
                        <Paper className={classes.loginForm}>
                           {!isError.show &&
                               <React.Fragment>
                                   {
                                        isDone ? 
                                            <div>
                                                <Typography style={{color: "#fff"}} variant="h6">Check your email.</Typography>
                                                <Typography style={{color: "#fff", paddingTop: "1em"}}>We have just sent you a link to reset your password.</Typography>
                                            </div>
                                        :
                                        <React.Fragment>
                                                <Typography style={{color: "#fff"}}>Enter email address.</Typography>
                                                <TextField  {...fs.model("email")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Email Address"/>
                                                
                                                <div style={{textAlign: "right", padding: "1em 0"}}>
                                                    {
                                                        isLoading ?
                                                        <CircularProgress size={30}/>
                                                        :
                                                        <Button onClick={onLogin} color="primary" variant="contained">Submit</Button>
                                                    }
                                                </div>
                                        </React.Fragment>
                           }
                               </React.Fragment>
                           }

                           {isError.show &&
                               <div>
                                    <Typography style={{color: "#fff"}} variant="h6">We're unable to process your request.</Typography>
                                    <Typography style={{color: "#e80000", paddingTop: "1em"}} variant="subtitle2">{isError.message}</Typography>
                                    <Button style={{marginTop: "2em"}} onClick={()=>{setIsError({show: false, message: ""})}} color="primary" variant="contained" fullWidth>Try Again</Button>
                                </div>
                           }
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(ForgotPassword);
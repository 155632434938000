import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from "../services/crud";
import config from '../config/config';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    team: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 2em"
    },
    teamImg: {
        //height: "375px",
        width: '100%'
    }
}));

  const slides = [
    {
      label: 'Lorem ipsum dolor sit amet,',
      imgPath:
        './assets/images/home1.jpg',
    },
    {
        label: 'consectetur adipiscing elit',
        imgPath:'./assets/images/home2.jpg',
    },
    {
        label: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        imgPath: './assets/images/home3.jpg',
    }
  ];

const Team = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [team, setTeam] = React.useState([]);
    const _team = crud('teams');

    const getTeam = async () => {
        let res = await _team.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    setTeam(res.data);
                }
            }
        }
    }

    React.useEffect(()=>{
        getTeam();
    },[]);

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = slides.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div>
            <Box className={classes.team} style={{padding: '0 0 3em 0'}}><img src="./assets/images/AboutUs-02.png"/></Box>
            
            <div className="content-main-padding">
            {(
                team.map((t, idx)=>(
                    <div key={idx}>
                        <Grid container>
                            <Grid item md={6} >
                                <div className={classes.team}>
                                    <img src={`${config.app.server.url}/storage/${t.photo[0]}`} width="100%" className={classes.teamImg} ></img>
                                </div>
                            </Grid>
                            <Grid item md={6} >
                                <Typography variant="h4">{t.name}</Typography><br/>
                                <Typography>
                                    {t.text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                ))
            )}
            </div>
        </div>
    )
}

export default Team;
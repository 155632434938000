import $http from './http';
import config from '../config/config';
import qs from 'qs';

const entryPoint = config.app.server.url + '/storage';

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

const provider = {
  dataURItoBlob,

  list: (directory) => {
    return $http
      .get(entryPoint, {
        params: {
          path: directory,
        },
      })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  download: (path) => {
    window.open(entryPoint + '?path=' + path);
  },

  upload: (file, params) => {
    var formData = new FormData();
    formData.append('file', file);

    return $http({
      method: 'post',
      url: entryPoint,
      data: formData,
      params: {
        path: params.path,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        if (params.onUploadProgress) {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          params.onUploadProgress(progressEvent, progress);
        }
      },
    })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mkdir: (params) => {
    return $http({
      method: 'post',
      url: entryPoint,
      data: {
        type: 'create',
        path: params.path,
      },
    })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  remove: (params) => {
    // { files: [ { path: 'xxx' }] }
    let p = qs.stringify(params);
    console.log(p);
    return $http({
      method: 'delete',
      url: entryPoint + '?' + p,
    })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  copy: (params) => {},
};

export default provider;

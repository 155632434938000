import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { crud } from '../../services/crud';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';

const TextContent = (props) => {
    const history = useHistory();
    const {idx, applicant, data, model = "requirement"} = props;
    const r = crud(model + "s");
    const [state, setState] = React.useState(data || {
        [`${model}Id`]: idx,
        applicant: applicant._id,
        status: "pending",
        status_:{label: "Pending", value: "pending"},
    });

    let check = false;

    if(data) {
        if(data._id) {
            check = true;
        }
    }

    const [isLoading, setIsLoading] = React.useState(false);

    const [isEdit, setIsEdit] = React.useState(!check);

    const onSubmit = async () => {
        setIsLoading(true);
        const res = await r.save(state);
        history.go(0);
    }

    const inputOnChange = (evt) => {
        const st = state;
        setState({
            ...state,
            text: evt.target.value
        })
    }

    return (
        <div style={{ margin: '1.5em 0', display: "flex", alignItems: "center", flexDirection: "column" }}>
            <div style={{width: "100%"}}>
                <TextField value={state.text} placeholder="Write something..." onChange={inputOnChange} disabled={!isEdit} multiline rows={6} variant="outlined" size="small" fullWidth/>
                {
                    isLoading ?
                        <div style={{padding: "1em 0"}}>
                            <CircularProgress size={30}/>
                        </div>
                    :
                    !isEdit ? 
                    <Button startIcon={<EditIcon style={{color: "#fff"}}/>} disabled={state.status == "confirmed"} onClick={()=>{setIsEdit(true)}} color="primary" style={{ margin: '0.5em 0' }} variant="contained" size="small">
                        <Typography>
                            Edit
                        </Typography>
                    </Button>
                    :<Button startIcon={<SaveIcon style={{color: "#fff"}}/>} onClick={onSubmit} color="primary" style={{ margin: '0.5em 0' }} variant="contained" size="small">
                        <Typography>
                            Submit
                        </Typography>
                    </Button>
                }
            </div>
            { state.text &&
                <div>
                    {
                        state.status == "confirmed" ?
                            <Chip style={{margin: "2em 0"}} icon={<CheckCircleIcon />} color="primary" label={state.status_.label}/>
                        :
                            <Chip style={{margin: "2em 0"}} label={state.status_.label}/>
                    }
                </div>
            }
        </div>
    );
}

export default TextContent;
import React, {useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import {withRouter} from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import { AuthContext } from '../../auth/AuthContext';
import { crudAgent } from '../../services/agentPortalService';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
    }
  },
  appBar: {
      zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('lg')]: {
      padding: "0 90px"
    }
  },
  content: {
    flexGrow: 1,
    //marginTop: "80px",
    minHeight: "calc(100vh - 80px)"
  },
  title: {
    color: grey[50]
  },
  logo: {
    height: "80px"
  },
  footer: {
    background: orange[700],
    color: "#fff",
    width: "100%",
    padding: "3em 6em"
  },
  profile:{
    display: "flex",
    margin: "0 0.2em",
    padding: "0.75em",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: orange[700],
      color: "#fff"
    }
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menuIcon: {
    color: "#fff",
    fontSize: "40px"
  },
  menuList: {
    background: "rgba(0, 0, 0, 0.87)"
  },
  menuItem: {
    minWidth: "160px",
    fontFamily: "Poppins",
    color: '#FFF',
    "&:hover": {
      background: "#404040"
    }
  }
}));

function FullLayout(props) {
  const {children, location, history} = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, logout } = React.useContext(AuthContext);
  // const [me, setMe] = React.useState(user.user.info);
  const [header, setHeader] = useState("header");
  const art = crudAgent('auto');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const [mabuhay, setMabuhay] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const getMabuhay = async () => {
  //   let res = await art.findOne(me.email);
  //   if(res) {
  //       if(res.data) {
  //         setMabuhay(res.data);
  //       }
  //   }
  // }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("bg-transparent")
    } else if (window.scrollY > 70) {
      return setHeader("bg-grey")
    } 
  }
  
  React.useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  // React.useEffect(()=>{
  //   getMabuhay();
  // },[]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history]);


  const appBarItems = [
    //{title: "Home", label: "Home", href: "/home"},
    {title: "About Us", label: "About Us", href: "/team"},
    {title: "Products", label: "Products", href: "/products"},
    {title: "Testimonials", label: "Testimonials", href: "/testimonials"},
    {title: "Media", label: "Media", href: "/videos"},
    //{title: "Trainings", label: "Trainings", href: "/trainings"},
    // {title: "Blog", label: "Blog", href: "/blog"},    
    //{title: "Lets Talk", label: "Lets Talk", href: "/webinars"}
  ];

  const appBarItemsMobile = [
   // {title: "Home", label: "Home", href: "/home"},
    {title: "About Us", label: "About Us", href: "/team"},
    {title: "Products", label: "Products", href: "/products"},
    {title: "Testimonials", label: "Testimonials", href: "/testimonials"},
    {title: "Media", label: "Media", href: "/videos"},
    //{title: "Trainings", label: "Trainings", href: "/trainings"},
    // {title: "Blog", label: "Blog", href: "/blog"},
    //{title: "Lets Talk", label: "Lets Talk", href: "/webinars"}
  ];

  // if(isAgent) {
  //   appBarItemsMobile.push(
  //     {title: "Mabuhay", label: "Mabuhay", href: config.app.portal.url},
  //     {title: "Requirements", label: "Requirements", href: "/requirements"}
  //   );
  // } else {
  //   appBarItemsMobile.push(
  //     {title: "Requirements", label: "Requirements", href: "/requirements"}
  //   );
  // }

  const linkOnClick = (route) => {
    history.push(`${route}`);
  }

  const ifActive = (href) => {
    return location.pathname.startsWith(href);
  }

  const onLogout = () => {
    logout();
    history.replace(`/home`);
  }

  // const finit =  user.user.info.firstname || user.user.email;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
            {children}
      </main>
    </div>
  );
}

export default withRouter(FullLayout);

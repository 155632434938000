import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { crud } from '../../services/crud';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const TextConfirm = (props) => {
    const history = useHistory();
    const {idx, applicant, data, model = "requirement"} = props;
    const r = crud(model + "s");
    const [state, setState] = React.useState(data || {
        [`${model}Id`]: idx,
        applicant: applicant._id,
        status: "pending",
        status_:{label: "Pending", value: "pending"},
        text: ""
    });

    const onConfirm = async () => {
        props.setIsLoading(true);
        const res = await r.save({
            ...state,
            status: "confirmed",
            status_:{label: "Confirmed", value: "confirmed"}
        });
        history.go(0);
    }

    return (
        <div style={{ margin: '1.5em 0', display: "flex", alignItems: "center", flexDirection: "column" }}>
            { state.text.trim() !== "" &&
                <React.Fragment>
                    <TextField value={state.text} disabled multiline rows={6} variant="outlined" size="small" fullWidth/>
                    <div style={{ margin: '2em 0' }}>
                        {
                            state.status == 'confirmed' ?
                            state.file && <Chip style={{margin: "2em 0"}} color="primary" icon={<CheckCircleIcon />} label={state.status_.label}/>
                            :
                            <Button onClick={onConfirm} color="primary" style={{ margin: '0.5em 0' }} variant="contained" size="small">
                                <Typography>
                                    confirm
                                </Typography>
                            </Button>
                        }
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default TextConfirm;
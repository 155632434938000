import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import StateHelper from '../services/stateHelper';
import { isRequired } from '../services/validators';
import {AuthContext} from '../auth/AuthContext';
import config from '../config/config';
import $http from '../services/http';
import qs from 'qs';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    content: {
        width: "100%",
        padding: "5em 5em",
        minHeight: "calc(100vh - 80px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url('./assets/images/login-bg-01.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    loginForm: {
        background: "rgba(255,255,255,0.1)",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "4em 3em"
    },
    joinInput: {
        background: "#fff",
        margin: "0.5em 0"
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "80%",
        padding: "1em 3em"
    }
}));

const Login = (props) => {
    const { history } = props;
    const classes = useStyles();
    const {setUser} = React.useContext(AuthContext);
    const [isFailed, setIsFailed] = React.useState(false);

    const e = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    // if(e.e === undefined) {
        
    // }

    const [state, setState] = React.useState({email: e.e});
    fs.useState(state, setState);
    fs.useValidator({
        email: isRequired,
        password: isRequired
    });

    const localLogin = async () => {
        let res = {};
    
        try {
          res = await $http({
            method: 'post',
            url: config.app.server.url + `/auth-local/login`,
            data: {
              ...state,
              password: state.password.trim()
            },
          });
        } catch (err) {
          res.data = {};
        }
    
        if (!res.data.user) {
          // console.log('invalid login');
          setIsFailed(true);
          return;
        }
        
        const user = {
          user: res.data.user,
          jwt: res.data.jwt,
          provider: {
            provider: 'local',
          },
          lastLogin: new Date()
        };

        $http.defaults.headers.common["Authorization"] = "Bearer " + user.jwt;

        setUser(user);
        props.history.replace('/requirements');
      };

      const onLogin = async () => {
        await fs.validateStateWithWait();
        if(!fs.hasErrors()) {
            localLogin();
        }
    }

    return (
        <div>
            <div className={classes.content}>
                <Grid container>
                    <Grid sm={12} md={8} item className={classes.logoContainer}>
                        {/*<img className={classes.logo} src="./assets/images/logo-nopads.png"/>*/}
                    </Grid>
                    <Grid sm={12} md={4} item>
                        <Paper className={classes.loginForm}>
                            <TextField  {...fs.model("email")} InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Email Address"/>
                            <TextField type="password" {...fs.model("password")}  InputProps={{ className: classes.joinInput }} variant="outlined" size="small" placeholder="Password"/>

                            {isFailed && <Typography style={{color: "#f44336"}} variant="subtitle2">Email or password is incorrect.</Typography>}
                            <a href="/forgotpassword" onClick={(e)=>{e.preventDefault(); history.push("/forgotpassword")}} style={{textAlign: "right", color: "#fff", padding: "0.5em 0"}}>Forgot Password?</a>
                            <div style={{textAlign: "right", padding: "1em 0"}}><Button onClick={onLogin} color="primary" variant="contained">Login</Button></div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Login);
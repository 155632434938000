import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { crud } from '../services/crud';
import { format } from 'date-fns';
import config from '../config/config';
import { withRouter } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1
    },
    banner: {
        width: "100%",
        height: "350px",
        background: cyan[900],
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    testimonialsPhoto: {
        height: "300px",
        width: "300px",
        borderRadius: "50%",
        marginBottom: "1.5em"
    },
    testimonialsItemInner: {
        width: "100%",
        padding: "2em 3em",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        background: "teal",
        color: "#000",
        height: "100%"
    },
    testimonialsItem: {
        padding: "0.5em"
    },
    testimonialsContainer: {
        padding: "3em 0",
        height: "100%"
    }
}));

const Testimonials = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [testimonials, setTestimonials] = React.useState([]);
    const art = crud('testimonials');

    const getTestimonials = async () => {
        const prod = crud('testimonials');
        let res = await prod.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res){
            if(res.data) {
                if(res.data.length > 0) {
                    setTestimonials(res.data);
                }
            }
        }
    }

    React.useEffect(()=>{
        getTestimonials();
    },[]);

    return (
        <div className="content-main-padding reviews-page">
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
                    <Typography variant="h4" className="heading1 font-face-AG" style={{textAlign:"left", color: "#ff3838", textTransform: "uppercase", paddingTop: "2em" }}>Testimonials</Typography>
                    <Typography className="heading2 font-face-LB" variant="h4" style={{paddingTop: "0.3em", textAlign:"left", color: '#fff' }}>PARTNERS AND ASSOCIATES TESTIMONIALS</Typography>
                <div>
                    <Grid container className={classes.testimonialsContainer}>
                        {(
                            testimonials.map((t, i)=>(
                            <Grid key={i} item sm={12} md={12} lg={4} className={classes.testimonialsItem}>
                                <div className={classes.testimonialsItemInner}>
                                    <div className={classes.testimonialsPhoto+ ' testimonials-photo'} style={{background: `url(${config.app.server.url}/storage/${t.photo[0]}) no-repeat center center / cover`}} ></div>
                                    <Typography className={classes.joinText} variant="h6" style={{fontStyle: "italic", marginTop: "1em", fontSize: "small"}}>"<span dangerouslySetInnerHTML={{__html: t.comments.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></span>"</Typography>
                                    <Typography className={classes.joinText} style={{paddingTop: "1em"}}>{t.name}</Typography>
                                    <Typography variant="body2" className={classes.joinText}>{t.title}</Typography>
                                    </div>
                            </Grid>
                            ))
                        )}
                    </Grid>
                </div>
            </div>
    )
}

export default withRouter(Testimonials);
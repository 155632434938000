import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const LinkContent = (props) => {
    const {idx, applicant, data, link} = props;
    const [state, setState] = React.useState(data || {
        requirementId: idx,
        applicant: applicant._id,
        status: "pending",
        status_:{label: "Pending", value: "pending"},
    });

    return (
        <div style={{ margin: '1.5em 0', display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Button startIcon={<OpenInNewIcon style={{color: "#fff"}}/>} color="primary" style={{ margin: '1.5em 0' }} variant="contained" target="_blank" href={link} size="small">
                <Typography>
                Open
                </Typography>
            </Button>
            <div>
                {
                    state.status == "confirmed" ?
                        <Chip style={{margin: "2em 0"}} icon={<CheckCircleIcon />} color="primary" label={state.status_.label}/>
                    :
                        <Chip style={{margin: "2em 0"}} label={state.status_.label}/>
                }
            </div>
        </div>
    );
}

export default LinkContent;
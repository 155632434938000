import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import StateHelper from '../services/stateHelper';
import { crud } from '../services/crud';
import { isRequired } from '../services/validators';
import { ApplicantContext } from '../stores/ApplicantStore';
import qs from 'qs';

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        padding: "5em 5em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 80px)",
        backgroundPosition: "center",
        backgroundImage: "url('./assets/images/login-bg-02.png')",
        backgroundSize: "cover",
    },
    joinInput: {
        background: "#fff"
    },
    joinInputs: {
        display: "flex",
        flexDirection: "column",
        width: "400px",
        backgroundColor: "rgba(255,255,255,0.1)",
        padding: "2em 2em"
    }, 
    datePicker: {
        margin: '1em 0',
        width: '100%',
        background: "#fff"
    }
}));

const Requirements = (props) => {
    const { history } = props;
    const classes = useStyles();
    const {setApplicant} = React.useContext(ApplicantContext);
    const [state, setState] = React.useState({ birthday: null });
    fs.useState(state, setState);
    fs.useValidator({
        firstname: isRequired,
        lastname: isRequired,
        email: isRequired,
        confirmEmail: isRequired,
    });

    const r = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    const onSubmit = async () => {
        await fs.validateStateWithWait();
        await checkEmail();
        await confirmEmail();
        if(!fs.hasErrors()) {
            setApplicant(state);
            if(r.r) {
                history.push(`/personal-details?r=${r.r}`);
            } else {
                history.push("/personal-details");
            }
        }
    }

    const checkEmail = async () => {
        const a = crud("applicants/verify");
        const res = await a.find({email: state.email});
        if(res){
            if(res.data.length > 0) {
                fs.setState({
                    _errors: {
                        ...state._errors,
                        email: {
                            "invalid": "true",
                            "error": "isRequired",
                            "message": "This email address is already in use."
                        }
                    }
                });
            }
        }
    }

    const confirmEmail = async () => {
        if(state.email !== state.confirmEmail) {
            fs.setState({
                _errors: {
                    ...state._errors,
                    confirmEmail: {
                        "invalid": "true",
                        "error": "isRequired",
                        "message": "The email addresses do not match."
                    }
                }
            });
        }
    }

    React.useEffect(()=>{
        console.log(r);
        if(r.e) {
            fs.setState({
                email: r.e,
                confirmEmail: r.e
            });
        }
    }, []);

    return (
        <div className={classes.root}>
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
            <div className={classes.content+ ' apply-now-container'}>
                <Grid container>
                    <Grid className={'apply-now-label'} xs={12} sm={12} md={7} item style={{textAlign: "right", padding: "1em 3em", borderRight: "solid 0px #fff"}}>
                        <div className={'apply-now-inner-container'}>
                        <Typography variant="h5">Register</Typography>
                        <Typography>Be part of our team.</Typography>
                        </div>
                    </Grid>
                    <Grid className={'apply-now-form'} xs={12} sm={12} md={5} item >
                        <div className={classes.joinInputs+' join-form-container'}>
                           <div style={{margin: "0.5em 0", width: "100%"}}>
                                <TextField 
                                    {...fs.model("firstname")} 
                                    InputProps={{ className: classes.joinInput }}
                                    variant="outlined"
                                    size="small"
                                    placeholder="First Name"
                                    fullWidth
                                    helperText={(state._errors && state._errors["firstname"]) ? state._errors["firstname"].message : ""}
                                />
                            </div>
                            <div style={{margin: "0.5em 0", width: "100%"}}>
                                <TextField 
                                    {...fs.model("lastname")} 
                                    InputProps={{ className: classes.joinInput }}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Last Name"
                                    fullWidth
                                    helperText={(state._errors && state._errors["lastname"]) ? state._errors["lastname"].message : ""}
                                />
                            </div>
                            <div style={{margin: "0.5em 0", width: "100%"}}>
                                <TextField 
                                    {...fs.model("email")} 
                                    InputProps={{ className: classes.joinInput }}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Email Address"
                                    fullWidth
                                    helperText={(state._errors && state._errors["email"]) ? state._errors["email"].message : ""}
                                />
                            </div>
                            <div style={{margin: "0.5em 0", width: "100%"}}>
                                <TextField 
                                    {...fs.model("confirmEmail")} 
                                    InputProps={{ className: classes.joinInput }}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Confirm Email Address"
                                    fullWidth
                                    helperText={(state._errors && state._errors["confirmEmail"]) ? state._errors["confirmEmail"].message : ""}
                                />
                            </div>     
                            <div style={{textAlign: "right", padding: "1em 0"}}>
                                <Button onClick={onSubmit} color="primary" variant="contained" disabled={state._errors && Object.keys(state._errors).length > 0}>Submit</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Requirements);
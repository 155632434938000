import React from 'react';
import config from '../config/config';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { useModal } from './Modal';

const Recaptcha = props => {
    const { closeModal } = useModal();
    const {setStatus, onSubmit} = props;
    const expired = () => {
        setStatus({isLoading: false, isSuccess: false, isError: true});
    }

    const verify = async (token) => {
        onSubmit(token);
        closeModal();
    }
    
    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.render('g-recaptcha', {
            'sitekey' : config.reCaptcha.siteKey,
            'callback' : verify,
            'theme' : config.reCaptcha.theme,
            'expired-callback	': expired
            });
        });
    }

    const handleClose = () => {
        setStatus({isLoading: false, isSuccess: false, isError: true});
        closeModal();
    }

    React.useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js`;
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);
    }, []);

    return (
        <div style={{padding: "0.5em", minHeight: "105px"}}>
            <div style={{textAlign: "right", width: "100%"}}>
                <IconButton onClick={handleClose}>
                    <CancelIcon />
                </IconButton>
            </div>
            <div style={{padding: "1em"}}>
                <div id="g-recaptcha"></div>
            </div>
        </div>
    )
}

export default Recaptcha;
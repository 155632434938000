import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { crud } from '../../services/crud';
import config from '../../config/config';
import path from 'path';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';

const UploadConfirm = (props) => {
    const history = useHistory();
    const {idx, applicant, data, model = "requirement"} = props;
    const r = crud(model + "s");
    const [state, setState] = React.useState(data || {
        [`${model}Id`]: idx,
        applicant: applicant._id,
        status: "pending",
        status_:{label: "Pending", value: "pending"}
    });

    const onConfirm = async () => {
        props.setIsLoading(true);
        const res = await r.save({
            ...state,
            status: "confirmed",
            status_:{label: "Confirmed", value: "confirmed"}
        });
        history.go(0);
    }

    const downloadFile = (file) => {
        const f = fetch(`${config.app.server.url}${file.url}`);
        fetch(`${config.app.server.url}${file.url}`)
        .then(res => res.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${path.basename(file.name)}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(()=>{console.log("Something went wrong.")});
      }

    return (
        <div style={{ margin: '1.5em 0', display: "flex", alignItems: "center", flexDirection: "column" }}>
            {state.file_ &&  <React.Fragment>
                <div style={{border: "2px solid #fff", padding: "1em 2em", width: "100%", textAlign: "center", borderRadius: "8px"}}>
                    <Typography>Submitted:</Typography>
                    <Typography variant="subtitle2">{path.basename(state.file_.name)}</Typography>
                    <Button variant="outlined" startIcon={<GetAppIcon style={{color: "#fff"}}/>} color="primary" style={{ margin: '0.75em 0' }} onClick={()=>{downloadFile(state.file_)}}>
                            <Typography>review</Typography>
                    </Button>
                </div>
                <div style={{ margin: '2em 0' }}>
                    {
                        state.status == 'confirmed' ?
                        state.file && <Chip style={{margin: "2em 0"}} color="primary" icon={<CheckCircleIcon />} label={state.status_.label}/>
                        :
                        <Button onClick={onConfirm} color="primary" style={{ margin: '0.5em 0' }} variant="contained" size="small">
                            <Typography>
                                confirm
                            </Typography>
                        </Button>
                    }
                </div>
                </React.Fragment>
            }
        </div>
    );
}

export default UploadConfirm;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { cyan, orange } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import StateHelper from '../services/stateHelper';
import DateFnsUtils from '@date-io/date-fns';
import Avatar from "@material-ui/core/Avatar";
import { crud } from '../services/crud';
import { isRequired } from '../services/validators';
import { AuthContext } from '../auth/AuthContext';
import {format} from 'date-fns';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import $http from '../services/http';
import config from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import path from 'path';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const fs = new StateHelper();

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        flexGrow: 1,
        paddingBottom: '90px',
        background: '#000'
    },
    content: {
        width: "100%",
        background: "#000",
        color: "#fff",
        minHeight: "calc(100vh - 108px)",
        padding: "4.5em 13%"
    },
    grid: {
        margin: "2em 0"
    },
    gridItem: {
        padding: "0.75em 0"
    },
    avatarGrid: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        margin: "0 0.2em",
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: orange[700]
    },
    avatarFail: {
        margin: "0 0.2em",
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: "#f44336"
    },
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    badge: {
        marginLeft: "1.5em"
    }
}));

const renderInput = (props) => (
    <TextField
        {...props}
        size="small"
        InputProps={{ ...props.InputProps,style: {background: '#fff'} }}
    />
);

const ApplicantItem = (props) => {
    const {onAccept, app, onOpen} = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid md={3}>
                <a href={`/requirements/${app._id}`} onClick={(e)=>{e.preventDefault(); onOpen(app._id)}} style={{color: "#fff"}}>
                    <Typography className={classes.gridItem}>{`${app.firstname} ${app.lastname}`}</Typography>
                </a>
            </Grid>
            
            <Grid md={6} className={classes.avatarGrid}>
                {
                    isLoading ? 
                        <CircularProgress size={25}/>
                    :
                    <React.Fragment>
                        <Button
                            style={{margin: "0 0.2em"}}
                            color="primary"
                            onClick={()=>{setIsLoading(true); onAccept(app._id)}}
                        >
                            Accept
                        </Button>
                        <Button
                            style={{margin: "0 0.2em"}}
                            color="secondary"
                        >
                            Decline
                        </Button>
                    </React.Fragment>
                }
            </Grid>
            <Grid md={3}>
            </Grid>
        </React.Fragment>
    )
}

const Downloadables = (props) => {
    const { history } = props;
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [downloadables, setDownloadables] = React.useState([]);
    const a = crud('downloadables');

    const me = user.user.info;

    const getDownloadables = async () => {
        const res = await a.find({});
        if(res.data){
            if(res.data.length > 0) {
                setDownloadables(res.data);
            }
        }
    }

    const downloadFile = (file) => {
        fetch(`${config.app.server.url}${file.url}`)
        .then(res => res.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${path.basename(file.name)}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(()=>{console.log("Something went wrong.")});
      }

    React.useEffect(()=>{
        getDownloadables();
    },[]);

    return (
        <div className={classes.root}>
            {/* <pre>{JSON.stringify(articles, null, 4)}</pre> */}
            <div className={classes.content}>
               <div>
                    <Typography variant="h5">Downloadables</Typography>
                    <Typography>These are the requirements to be encoded as Financial Wealth Planner. Download the forms needed and upload them here after filling them up.</Typography>
               </div>
               <div>
                   <Grid className={classes.grid} container>
                       <Grid md={12} sm={12}>
                        <Grid md={4} sm={4}>
                            <Typography style={{fontWeight: "600"}}>Name of Downloadable</Typography>
                        </Grid>
                        <Grid md={3} sm={4}>
                            <Typography style={{fontWeight: "600"}}></Typography>
                        </Grid>
                        <Grid md={5} sm={4}>
                            <Typography style={{fontWeight: "600"}}>Version Date</Typography>
                        </Grid>
                        </Grid>
                        {(
                            downloadables.map((d, idx)=>(
                                <React.Fragment>
                                    <Grid md={12} sm={12}>
                                    <Grid md={4} sm={12}>
                                        <Typography className={classes.gridItem}>{`${d.title}`}</Typography>
                                    </Grid>
                                    <Grid md={4} sm={12} className={classes.gridItem}>
                                        <a style={{color: "#f57c00"}} href={path.basename(d.file_.name)} onClick={(e)=>{e.preventDefault(); downloadFile(d.file_);}}><Typography>{path.basename(d.file_.name)}</Typography></a>
                                    </Grid>
                                    <Grid md={4} sm={12}>
                                        <Typography className={classes.gridItem}>{format(new Date(d.createdAt), "MMMM dd, yyyy")}</Typography>
                                    </Grid>
                                    </Grid>
                                </React.Fragment>
                            ))
                        )}
                   </Grid>
               </div>
            </div>
            {/* <pre>{JSON.stringify(downloadables, null, 4)}</pre> */}
        </div>
    )
}

export default withRouter(Downloadables);